import { useMemo } from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import format from 'date-fns/format'

import { useHONOPrice } from 'app/hooks/graph/useGraph'
import { useBalanceOf } from 'app/hooks/useBalance'
import { DAO_ADDRESS, HONO_V2_ADDRESS } from 'app/utils/constants/contract'
import { useLatestRevenue } from 'app/hooks/useBackPrice'

export const DAO = () => {
  const { data: honoPrice = 0 } = useHONOPrice()
  const { data: honoBalance } = useBalanceOf({
    tokenAddress: HONO_V2_ADDRESS,
    contractAddress: DAO_ADDRESS
  })
  const { data: latestRevenue } = useLatestRevenue()

  const balance = useMemo(() => {
    return honoBalance && honoPrice && +honoBalance
  }, [honoBalance, honoPrice])

  return (
    <Card>
      <CardContent
        sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: 'rgb(55, 71, 79)' }}
      >
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          DAO Pool
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
          {balance && `~${balance.toFixed(3)} HONO`}
        </Typography>

        <Typography variant={'h3'} fontSize={'15px'} mt={2}>
          {latestRevenue && `last capital injection ${format(new Date(latestRevenue?.date), 'dd/MM/yyyy HH:mm')}`}
        </Typography>

        <Typography variant={'h2'} fontSize={'14px'} mt={3}>
          DAO Pool is the allocation for starting new fan coin projects governed by xHONO
        </Typography>
      </CardContent>
    </Card>
  )
}
