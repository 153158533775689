export const calcAmount1FromAmount0Ratio = (
  amount0: number,
  ratio: number,
  {
    tickLower,
    tickUpper
  }: {
    tickLower: number
    tickUpper: number
  }
) => {
  const amount = amount0 * 10 ** 18 // amount0 is HONO (decimal 18)

  const lower = Math.pow(1.0001, tickLower) // Convert tick to price
  const upper = Math.pow(1.0001, tickUpper) // Convert tick to price

  const pCur = ratio
  const pUp = Math.sqrt(upper)
  const pLow = Math.sqrt(lower)

  const L = (amount * pCur * pUp) / (pUp - pCur)

  const amount1 = L * (pCur - pLow)

  return amount1 / 10 ** 6 // amount 1 is USDC
}
