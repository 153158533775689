import Typography from '@mui/material/Typography'

import { FarmList } from './FarmList'
import { PageTitle } from 'app/components/PageTitle'

export const Harvest = () => {
  return (
    <div>
      <PageTitle title='MangaFi DApp: Harvest' />
      <Typography variant='h2' color='text.primary'>
        Harvest
      </Typography>
      <Typography variant='body1' color='text.secondary' mb={3}>
        Claim your rewards.
      </Typography>

      <FarmList />
    </div>
  )
}
