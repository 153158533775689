import { PairItem } from 'app/types/LP'
import { ShortCrypt } from 'short-crypt'

export const BRIDGE_ADDRESS = '0x72Ab4BA9b22B27E3C9B2b8f45D8Ee0D0B5264674'
export const INUKO_BRIDGE_ADDRESS = '0x5c5C01F021c2183a61166C279F21d2e0e06E4Cbe'
export const EXCHANGE_POOL_ADDRESS = '0x5d1f3E5199c426c1b45720453373A4743568a5e5'
export const LOCK_POOL_ADDRESS = '0x0DF24D02681CFFbd740eC2cB8D719b1cB3c5C83C'

export const MINT_CURBOND_ADDRESS = '0x0c7B5D00018F1ba6dd711f6AD899022ea2D83678'
export const CURBOND_ADDRESS = '0xE7206d25763D4f55B5c7473ab31F6c1c15a295DA'

export const MANGA_ADDRESS = '0xc2cb89bbb5bba6e21db1dfe13493dfd7dcbabd68'
export const INUKO_ADDRESS = '0xEa51801b8F5B88543DdaD3D1727400c15b209D8f'
export const HONO_ADDRESS = '0x8F5ac7F3cfeFfB6EB638A8aDd2d32661F82C03FD'
export const xHONO_ADDRESS = '0x74e4C18Eb9D7390f6Bf7BC477d94B51608366048'
export const HONO_V2_ADDRESS = '0x0e16BD2Cd962FaDb4A23eC961BB170FfA25208A8'
export const LP_ENGINE_ADDRESS = '0x0614DABe34a2154b0d242f4cb7dbe234365AdD6E'
export const DISTRIBUTOR_ADDRESS = '0x7618Db15dA0027691dB33287355D11F2ee846d16'
export const HONO_HELPER_ADDRESS = '0x5d1f3E5199c426c1b45720453373A4743568a5e5' // @todo remove because same as exchange pool
export const DAO_ADDRESS = '0x1784cf268e4a5D48562F01506C6f570aBc35F9c1'

export const HONO_BACKING_SUBGRAPH_URL =
  'https://api.goldsky.com/api/public/project_clxwky5ihcgc201vf19ysho1u/subgraphs/manga-fi-holder/1.0.1/gn'
export const HONO_BACKING_SUBGRAPH_V2_URL =
  'https://api.goldsky.com/api/public/project_clxwky5ihcgc201vf19ysho1u/subgraphs/manga-fi-holder/1.0.2/gn'
export const HONO_BASE_SUBGRAPH_URL = 'https://api.thegraph.com/subgraphs/name/nuoanunu'

export const USDC_ADDRESS = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
export const LINK_ADDRESS = '0x514910771AF9Ca656af840dff83E8264EcF986CA'
export const WETH_ADDRESS = '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'
export const WST_ETH_ADDRESS = '0x7f39C581f595B53c5cb19bD0b3f8dA6c935E2Ca0'
export const USDC_X_ADDRESS = '0x9EC30aCf856341209cBfDEEA49bA855E7618d348'

export const HONOV2_USDC_TOKEN_ID = 780314

export const pairs: {
  [key in string]: PairItem
} = {
  'LINK-ETH': {
    tokenId: 554081,
    name: 'LINK-ETH', // token1 - token2
    logo1: 'https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700',
    logo2: 'https://assets.coingecko.com/coins/images/2518/small/weth.png',
    address1: LINK_ADDRESS,
    address2: WETH_ADDRESS,
    lpAddress: '0xA0f0f0b37f88FD5eeBF70232f3517e7CE6FfB6b9',
    internal: true
  },
  'HONO-USDC': {
    tokenId: HONOV2_USDC_TOKEN_ID,
    name: 'HONO V2-USDC', // token1 - token2
    logo1: '/hono-icon.svg',
    logo2: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    address1: HONO_ADDRESS,
    address2: USDC_ADDRESS,
    lpAddress: LP_ENGINE_ADDRESS,
    internal: false
  }
}

export const bridges = {
  [MANGA_ADDRESS]: BRIDGE_ADDRESS,
  [INUKO_ADDRESS]: INUKO_BRIDGE_ADDRESS
}

export const tvlFile =
  'https://firebasestorage.googleapis.com/v0/b/mangafi-production/o/tvl.json?alt=media&token=64e89cfb-c4c6-4869-8edc-d916e0018e78'
export const priceFile =
  'https://firebasestorage.googleapis.com/v0/b/mangafi-production/o/price.json?alt=media&token=c10731fc-f724-4657-be9f-71ad2f5f59b0'

//@ts-ignore
export const MaxUint256 = 0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffn
export const nullAddress = '0x0000000000000000000000000000000000000000'
export const scKey =
  'kTnSs7KfqbyCE9hthCuhCF6LBGvDdG87q77TgBvNk7yKRYXxzrsavAVLMHCCvHrnHPYRyTbf68WYx94yMQpqTMU53Wa7DwwbVehUf6EELp4Z6TeUe2zqDKfUTZ5PdPeX'
export const sc = new ShortCrypt(scKey)
