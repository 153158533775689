import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Address, parseEther } from 'viem'
import { waitForTransaction } from 'wagmi/actions'
import toast from 'react-hot-toast'

import { useDynamicContract, useStaticContract } from '../useContract'
import bridgeABI from 'app/utils/constants/abis/bridge.json'
import { MANGA_ADDRESS } from 'app/utils/constants/contract'
import { useAccount, useChainId } from 'wagmi'
import { balanceQueryKey } from '../useBalance'

export const useBridgeOutputAmount = (number: string, bridgeAddress: Address) => {
  const chainId = useChainId()
  const bridgeContract = useStaticContract(bridgeAddress, bridgeABI as any)

  return useQuery({
    queryKey: ['bridge_output_amount', bridgeAddress, chainId],
    queryFn: async () => bridgeContract.read.calculateOutputAmount([parseEther(number)])
  })
}

export const useBridgeFee = (bridgeAddress: Address) => {
  const bridgeContract = useStaticContract(bridgeAddress, bridgeABI as any)

  return useMutation({
    mutationFn: async ({ amount, senderAddress }: { amount: string; senderAddress: Address }) =>
      bridgeContract.read.estimateFee([parseEther(amount), senderAddress])
  })
}

export const useBridge = (fee: string, bridgeAddress: Address) => {
  const bridgeContract = useDynamicContract(bridgeAddress, bridgeABI as any)
  const client = useQueryClient()
  const { address = '' } = useAccount()
  const chainId = useChainId()

  return useMutation({
    mutationFn: async ({ amount }: { amount: string }) => {
      if (bridgeContract) {
        const hash = await bridgeContract?.write.sendToken([parseEther(amount)], {
          value: parseEther(fee)
        })

        const receipt = await waitForTransaction({
          hash
        })

        return receipt
      }
    },
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: balanceQueryKey(address, MANGA_ADDRESS, chainId)
      })
      toast.success('Mint successful, please wait for the token to be sent to your wallet.')
    }
  })
}
