import { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { Address } from 'viem'
import toast from 'react-hot-toast'

import { AddLPParams, useAddLP } from 'app/hooks/liquidity/useLiquidityPool'
import { nullAddress } from 'app/utils/constants/contract'
export interface AddLPProps extends AddLPParams {
  lpAddress: Address
}

export const AddLP = ({ tokenId, amountAdd1, amountAdd2, slippage, lpAddress, referrer }: AddLPProps) => {
  const [loading, setLoading] = useState(false)

  const { mutateAsync: addLp } = useAddLP(lpAddress, tokenId)

  const handleAddLP = async () => {
    try {
      setLoading(true)
      await addLp({
        tokenId,
        amountAdd1,
        amountAdd2,
        slippage,
        referrer: referrer || nullAddress
      })
    } catch (err) {
      toast.error(JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <LoadingButton
      loading={loading}
      variant={'contained'}
      color={'secondary'}
      size={'small'}
      onClick={handleAddLP}
      disabled={!amountAdd1 || !amountAdd2}
    >
      Add LP
    </LoadingButton>
  )
}
