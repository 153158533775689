import { useMemo } from 'react'
import { Address } from 'viem'
import Stack from '@mui/material/Stack'
import { Box, Card, Typography } from '@mui/material'
import styled from '@emotion/styled'
import Span from '@jumbo/shared/Span'
import { useLockedInfo } from 'app/hooks/exchange/useExchange'
import { VoteAction } from './VoteAction'
import { xHONO_ADDRESS } from 'app/utils/constants/contract'
import { formatNumber } from 'app/utils/helper'
import { useBalanceOf } from 'app/hooks/useBalance'
import { UnVoteAction } from './UnvoteAction'
import { FarmLogo } from '../harvest/FarmItem'

export enum VoteType {
  DAO = 1,
  POOL = 2
}

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1)
}))

export interface VoteItemProps {
  vote: {
    name: string
    fee: string
    poolMinter: string
    logo1: string
    logo2: string
  }
}

export const VoteItem = ({ vote }: VoteItemProps) => {
  const { data } = useLockedInfo()

  const { daoRate, poolRate, daoAddress, poolAddress } = useMemo(() => {
    if (data) {
      return data
    }

    return {
      daoRate: '',
      poolRate: '',
      daoAddress: '' as Address,
      poolAddress: '' as Address
    }
  }, [data])

  const { data: balanceXHONOInDAO } = useBalanceOf({
    tokenAddress: xHONO_ADDRESS,
    contractAddress: daoAddress
  })

  const { data: balanceXHONOInPool } = useBalanceOf({
    tokenAddress: xHONO_ADDRESS,
    contractAddress: poolAddress
  })

  return (
    <Card sx={{ mb: 1 }}>
      <Box
        display='flex'
        sx={{
          columnGap: {
            xs: 0,
            md: '4rem',
            lg: '8rem'
          },
          flexDirection: {
            xs: 'column',
            lg: 'row'
          },
          p: theme => theme.spacing(2, 1)
        }}
      >
        <Item>
          <Stack direction={'row'} alignItems={'center'}>
            <Item>
              <Box display='flex'>
                <FarmLogo src={vote.logo1} alt={vote.name} />
                <FarmLogo src={vote.logo2} alt={vote.name} />
              </Box>
            </Item>
            <Item>
              <Typography variant={'h6'} mb={0.5}>
                {vote.name}
              </Typography>
              <Typography variant={'body1'} color='text.secondary'>
                Fee: {vote.fee}
              </Typography>
            </Item>
          </Stack>
        </Item>
        <Item
          sx={{
            alignSelf: 'flex-start'
          }}
        >
          <Typography variant={'h6'} mt={1} lineHeight={1.25}>
            Pool Minter
          </Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {vote.poolMinter}
          </Typography>
        </Item>

        <Box display='flex' columnGap='2rem'>
          <Item
            sx={{
              alignSelf: 'flex-start'
            }}
          >
            <Typography variant={'h6'} mt={1} lineHeight={1.25}>
              xHONO Pool
            </Typography>
            <Typography variant={'body1'} color='text.secondary'>
              {poolRate ? formatNumber(+poolRate / 100) : '...'}
            </Typography>
            <Typography variant={'h6'} mt={1} lineHeight={1.25}>
              Current Weightage
            </Typography>
            <Typography variant={'body1'} color='text.secondary' mb={1}>
              {poolRate ? formatNumber(+poolRate) : '...'}
            </Typography>
            <Typography variant={'h6'} mt={1} lineHeight={1.25}>
              xHONO balance
            </Typography>
            <Typography variant={'body1'} color='text.secondary' mb={1}>
              {balanceXHONOInPool ? formatNumber(+balanceXHONOInPool) : '...'}
            </Typography>
            <VoteAction lockPool={VoteType.POOL} tokenAddress={xHONO_ADDRESS} />
            <Box mt='1rem' />
            <UnVoteAction lockPool={VoteType.POOL} tokenAddress={xHONO_ADDRESS} />
          </Item>
          <Item
            sx={{
              alignSelf: 'flex-start'
            }}
          >
            <Typography variant={'h6'} mt={1} lineHeight={1.25}>
              DAO Pool
            </Typography>
            <Typography variant={'body1'} color='text.secondary'>
              {daoRate ? formatNumber(+daoRate / 100) : '...'}
            </Typography>
            <Typography variant={'h6'} mt={1} lineHeight={1.25}>
              Current Weightage
            </Typography>
            <Typography variant={'body1'} color='text.secondary' mb={1}>
              {daoRate ? formatNumber(+daoRate) : '...'}
            </Typography>
            <Typography variant={'h6'} mt={1} lineHeight={1.25}>
              xHONO balance
            </Typography>
            <Typography variant={'body1'} color='text.secondary' mb={1}>
              {balanceXHONOInDAO ? formatNumber(+balanceXHONOInDAO) : '...'}
            </Typography>

            <VoteAction lockPool={VoteType.DAO} tokenAddress={xHONO_ADDRESS} />
            <Box mt='1rem' />
            <UnVoteAction lockPool={VoteType.DAO} tokenAddress={xHONO_ADDRESS} />
          </Item>
        </Box>
      </Box>
    </Card>
  )
}
