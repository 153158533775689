import { useAccount } from 'wagmi'
import { Card, CardContent, Typography } from '@mui/material'

import { useLPStakingRate } from 'app/hooks/graph/useGraph'
import { pairs } from 'app/utils/constants/contract'

export const StakingAPR = () => {
  const { address = '' } = useAccount()

  const { data: rate } = useLPStakingRate({
    tokenId: pairs['HONO-USDC'].tokenId,
    lpAddress: pairs['HONO-USDC'].lpAddress
  })

  return (
    <Card>
      <CardContent sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: '#60c3d7' }}>
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          HONO V2-USDC LP STAKING APY
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        {address ? (
          <>
            <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
              {rate?.toFixed(3)}%
            </Typography>
            <Typography variant={'h3'} fontSize={'16px'} mb={3}>
              extrapolated
            </Typography>
          </>
        ) : (
          <Typography variant={'h2'} fontSize={'18px'} fontWeight={500} mb={3}>
            Connect wallet to see rate
          </Typography>
        )}

        <Typography variant={'h2'} fontSize={'14px'}>
          APY is calculated as (% of eth gained per lp share) / (total days since launched) * (365 days)
        </Typography>
      </CardContent>
    </Card>
  )
}
