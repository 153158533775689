import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactGA from 'react-ga4'
import { HelmetProvider } from 'react-helmet-async'

import JumboApp from '@jumbo/components/JumboApp'
import AppLayout from './AppLayout'
import JumboTheme from '@jumbo/components/JumboTheme'
import AppRoutes from './AppRoutes'
import configureStore, { history } from './redux/store'
import JumboDialog from '@jumbo/components/JumboDialog'
import JumboDialogProvider from '@jumbo/components/JumboDialog/JumboDialogProvider'
import { SnackbarProvider } from 'notistack'
import AppProvider from './AppProvider'
import { config } from './config/main'
import JumboRTL from '@jumbo/JumboRTL/JumboRTL'
import Div from '@jumbo/shared/Div'
import { CircularProgress } from '@mui/material'
import JumboAuthProvider from '@jumbo/components/JumboAuthProvider'

import { getDefaultWallets, RainbowKitProvider, connectorsForWallets } from '@rainbow-me/rainbowkit'
import { argentWallet, trustWallet, ledgerWallet } from '@rainbow-me/rainbowkit/wallets'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { bsc, mainnet } from 'wagmi/chains'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'

import '@rainbow-me/rainbowkit/styles.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const store = configureStore()

const chains = [mainnet, bsc]
const infuraId = process.env.REACT_APP_INFURA_ID || ''
const projectId = process.env.REACT_APP_PROJECT_ID || ''

const { publicClient } = configureChains(chains, [
  infuraProvider({
    apiKey: infuraId
  }),
  publicProvider()
])

const { wallets } = getDefaultWallets({
  appName: 'MangaFi',
  projectId,
  chains
})

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [
      argentWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      ledgerWallet({ projectId, chains })
    ]
  }
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

function App() {
  ReactGA.initialize('G-XH0L12XSTD')

  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <BrowserRouter history={history}>
                <HelmetProvider>
                  <AppProvider>
                    <JumboApp activeLayout={config.defaultLayout}>
                      <JumboTheme init={config.theme}>
                        <JumboRTL>
                          <JumboDialogProvider>
                            <JumboDialog />
                            <SnackbarProvider
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right'
                              }}
                              maxSnack={3}
                            >
                              <JumboAuthProvider>
                                <AppLayout>
                                  <Suspense
                                    fallback={
                                      <Div
                                        sx={{
                                          display: 'flex',
                                          minWidth: 0,
                                          alignItems: 'center',
                                          alignContent: 'center',
                                          height: '100%'
                                        }}
                                      >
                                        <CircularProgress sx={{ m: '-40px auto 0' }} />
                                      </Div>
                                    }
                                  >
                                    <AppRoutes />
                                  </Suspense>
                                </AppLayout>
                              </JumboAuthProvider>
                            </SnackbarProvider>
                          </JumboDialogProvider>
                        </JumboRTL>
                      </JumboTheme>
                    </JumboApp>
                  </AppProvider>
                </HelmetProvider>
              </BrowserRouter>
            </Provider>
          </QueryClientProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  )
}

export default App
