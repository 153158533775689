import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'

import { useExchangePool } from 'app/hooks/exchange/useExchange'
import { AllowanceGuard } from 'app/components/AllowanceGuard'
import { EXCHANGE_POOL_ADDRESS, xHONO_ADDRESS } from 'app/utils/constants/contract'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { NativeBalanceGuard } from 'app/components/NativeBalanceGuard'

export interface ExchangeProps {
  amount: string
  fee: string
}

export const Exchange = ({ amount, fee }: ExchangeProps) => {
  const { mutate: exchange, error, isLoading } = useExchangePool()

  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        <AllowanceGuard
          tokenAddress={xHONO_ADDRESS}
          spenderAddress={EXCHANGE_POOL_ADDRESS}
          tokenName='xHONO'
          amount={amount ?? '0'}
        >
          <NativeBalanceGuard amount={fee ?? '0'} nativeTokenName='ETH'>
            <LoadingButton
              loading={isLoading}
              variant='contained'
              sx={{
                mt: '0.5rem'
              }}
              size={'small'}
              onClick={() => exchange({ amount, fee })}
              disabled={!amount}
            >
              Exchange
            </LoadingButton>
          </NativeBalanceGuard>
        </AllowanceGuard>
        {!!error && <p style={{ color: 'red' }}>{JSON.stringify(error)}</p>}
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
