import { useMutation, useQueryClient } from '@tanstack/react-query'
import { parseUnits } from 'viem'
import { useAccount, useChainId } from 'wagmi'
import { waitForTransaction } from 'wagmi/actions'
import toast from 'react-hot-toast'

import { useDynamicContract } from './useContract'
import mintXABI from 'app/utils/constants/abis/sellX.json'
import { CURBOND_ADDRESS, MINT_CURBOND_ADDRESS } from 'app/utils/constants/contract'
import { balanceQueryKey } from './useBalance'

export const useMintX = () => {
  const mintXContract = useDynamicContract(MINT_CURBOND_ADDRESS, mintXABI as any)
  const client = useQueryClient()
  const { address = '' } = useAccount()
  const chainId = useChainId()

  return useMutation({
    mutationFn: async ({ amount }: { amount: string }) => {
      if (mintXContract) {
        try {
          const hash = await mintXContract?.write.buyTokens([parseUnits(amount, 6)])
          const receipt = await waitForTransaction({
            hash
          })

          return receipt
        } catch (err) {
          throw err
        }
      }
    },

    onSuccess: () => {
      toast.success('Buy successful, please wait for the token to be sent to your wallet.')
      client.invalidateQueries({
        queryKey: ['user_balance', address]
      })
      client.invalidateQueries({
        queryKey: balanceQueryKey(MINT_CURBOND_ADDRESS, CURBOND_ADDRESS, chainId)
      })
    }
  })
}
