import { UserCredential } from 'firebase/auth'
import { loginWithGoogle, loginWithTwitter } from './firebase'

const authServices: {
  getCurrentUser: () => any
  signIn: ({ email, password }: { email: string; password: string }) => Promise<void>
  signInWithTwitter: () => Promise<UserCredential | undefined>
  signInWithGoogle: () => Promise<UserCredential | undefined>
} = {} as any

authServices.getCurrentUser = () => {
  try {
    return JSON.parse(localStorage.getItem('@mangafi_authUser')!)
  } catch {
    return null
  }
}

authServices.signInWithGoogle = async () => {
  const auth = await loginWithGoogle()

  return auth
}

authServices.signInWithTwitter = async () => {
  const auth = await loginWithTwitter()

  return auth
}

export default authServices
