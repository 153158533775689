import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import JumboCardFeatured from '@jumbo/components/JumboCardFeatured'

export const ReferralInformation = () => {
  return (
    // @ts-ignore
    <JumboCardFeatured
      showTick
      tickSx={{ bgcolor: '#ff164e' }}
      bgColor={['#ffce22', '#df752c']}
      direction={'column'}
      textAlign={'center'}
      imgSrc='/hono-bg.svg'
      imgSx={{ height: 254 }}
    >
      <Typography variant={'h3'} color={'common.white'} mb={2}>
        earn 0.05% from your friend’s harvest
      </Typography>
      <Link to='/referral'>
        <Button variant={'contained'} color='error' sx={{ mb: 0.5 }}>
          Learn More
        </Button>
      </Link>
    </JumboCardFeatured>
  )
}
