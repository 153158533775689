import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Address, formatEther, parseEther } from 'viem'
import { useAccount, useChainId } from 'wagmi'
import { waitForTransaction } from 'wagmi/actions'
import toast from 'react-hot-toast'

import { useDynamicContract, useStaticContract } from '../useContract'
import exchangeABI from 'app/utils/constants/abis/exchange.json'
import lockPoolABI from 'app/utils/constants/abis/lockPool.json'
import honoABI from 'app/utils/constants/abis/hono.json'
import { EXCHANGE_POOL_ADDRESS, HONO_V2_ADDRESS, LOCK_POOL_ADDRESS } from 'app/utils/constants/contract'
import { VoteType } from 'app/pages/vote/VoteItem'
import { REFRESH_INTERVAL } from 'app/utils/constants/app'

export const useExchangePool = () => {
  const exchangeContract = useDynamicContract(EXCHANGE_POOL_ADDRESS, exchangeABI as any)
  const client = useQueryClient()
  const { address = '' } = useAccount()

  return useMutation({
    mutationFn: async ({ amount, fee }: { amount: string; fee: string }) => {
      if (exchangeContract) {
        try {
          const hash = await exchangeContract?.write.exchange([parseEther(amount)], {
            value: parseEther(parseFloat(fee).toFixed(18))
          })
          const receipt = await waitForTransaction({
            hash
          })

          return receipt
        } catch (err) {
          throw err
        }
      }
    },
    onSuccess: () => {
      toast.success('Exchange successful, please wait for the token to be sent to your wallet.')
      client.invalidateQueries({
        queryKey: ['user_balance', address]
      })
    }
  })
}

export const useExchangeFee = (honoAmount: string) => {
  const HONOContract = useStaticContract(HONO_V2_ADDRESS, honoABI as any)
  const chainId = useChainId()

  return useQuery({
    queryKey: ['exchange_fee', honoAmount, chainId],
    queryFn: async () => {
      if (HONOContract) {
        const fee = await HONOContract.read.HONOToETH([parseEther(honoAmount)])

        return +formatEther(fee as unknown as bigint) * 0.025
      }
    }
  })
}

export const useLockedAmount = () => {
  const lockContract = useStaticContract(LOCK_POOL_ADDRESS, lockPoolABI as any)
  const { address = '' } = useAccount()
  const chainId = useChainId()

  return useQuery({
    queryKey: ['locked_amount', address, LOCK_POOL_ADDRESS, chainId],
    queryFn: async () => {
      if (lockContract) {
        const [amount1, amount2] = await Promise.all([
          lockContract?.read.userLockedAmount1([address]),
          lockContract?.read.userLockedAmount2([address])
        ])

        return {
          lockedDao: formatEther(amount1 as unknown as bigint),
          lockedPool: formatEther(amount2 as unknown as bigint)
        }
      }
    },
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useLockedInfo = () => {
  const lockContract = useStaticContract(LOCK_POOL_ADDRESS, lockPoolABI as any)
  const chainId = useChainId()

  return useQuery({
    queryKey: ['locked_info', LOCK_POOL_ADDRESS, chainId],
    queryFn: async () => {
      if (lockContract) {
        const ethSplitterRate = await lockContract?.read.getETHSplitterRate([])

        return {
          daoRate: formatEther(ethSplitterRate[0] as bigint),
          poolRate: formatEther(ethSplitterRate[1] as bigint),
          daoAddress: ethSplitterRate[2] as Address,
          poolAddress: ethSplitterRate[3] as Address
        }
      }
    },
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useLockPool = (lockPool: VoteType) => {
  const lockContract = useDynamicContract(LOCK_POOL_ADDRESS, lockPoolABI as any)
  const queryClient = useQueryClient()
  const chainId = useChainId()

  return useMutation({
    mutationFn: async ({ amount }: { amount: string }) => {
      if (lockContract) {
        const hash =
          lockPool === VoteType.DAO
            ? await lockContract?.write.lock1([parseEther(amount)])
            : await lockContract?.write.lock2([parseEther(amount)])

        const receipt = await waitForTransaction({
          hash
        })

        return receipt
      }
    },
    onSuccess: () => {
      toast.success('Lock successfully')
      queryClient.invalidateQueries(['locked_info', LOCK_POOL_ADDRESS, chainId])
    }
  })
}

export const useUnLockPool = (lockPool: VoteType) => {
  const lockContract = useDynamicContract(LOCK_POOL_ADDRESS, lockPoolABI as any)
  const queryClient = useQueryClient()
  const chainId = useChainId()

  return useMutation({
    mutationFn: async ({ amount }: { amount: string }) => {
      if (lockContract) {
        const hash =
          lockPool === VoteType.DAO
            ? await lockContract?.write.unlock1([parseEther(amount)])
            : await lockContract?.write.unlock2([parseEther(amount)])

        const receipt = await waitForTransaction({
          hash
        })

        return receipt
      }
    },
    onSuccess: () => {
      toast.success('Unlock successfully')
      queryClient.invalidateQueries(['locked_info', LOCK_POOL_ADDRESS, chainId])
    }
  })
}

export const useHONOInPool = () => {
  const exchangeContract = useStaticContract(EXCHANGE_POOL_ADDRESS, exchangeABI as any)
  const chainId = useChainId()

  return useQuery({
    queryKey: ['hono_in_pool', EXCHANGE_POOL_ADDRESS, chainId],
    queryFn: async () => {
      if (exchangeContract) {
        const amount = await exchangeContract?.read.getMaxOutputAmount([])

        return formatEther(amount as unknown as bigint)
      }
    },
    refetchInterval: REFRESH_INTERVAL
  })
}
