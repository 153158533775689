import React, { Suspense } from 'react'
import { Box, IconButton, Button } from '@mui/material'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import menus from './menus'
import JumboVerticalNavbar from '@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar'
import { DrawerHeader } from '@jumbo/components/JumboLayout/style'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar'
import useJumboSidebarTheme from '@jumbo/hooks/useJumboSidebarTheme'
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from '@jumbo/utils/constants/layout'
import Logo from '../../../../shared/Logo'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import Zoom from '@mui/material/Zoom'
import Div from '@jumbo/shared/Div'
import SidebarSkeleton from './SidebarSkeleton'
import { AddToMetamask } from 'app/components/AddToMetamask'
import { HONO_ADDRESS, HONO_V2_ADDRESS, xHONO_ADDRESS } from 'app/utils/constants/contract'

const Sidebar = () => {
  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                px: 3
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menus} />
          <Box mr={2}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '8px',
                pb: 1,
                pl: 4,
                pr: 3,
                overflow: 'hidden',
                margin: '0',
                cursor: 'pointer'
              }}
            >
              <Box
                component='a'
                href='https://app.uniswap.org/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0x0e16BD2Cd962FaDb4A23eC961BB170FfA25208A8'
                target='_blank'
                rel='noreferrer'
              >
                <Button
                  variant='contained'
                  sx={{
                    borderRadius: '20px',
                    color: '#000'
                  }}
                >
                  Buy HONO V2
                </Button>
              </Box>
              
              <AddToMetamask
                textColor='#fff'
                bgColor='#ff164e'
                tokenName='HONO V2'
                tokenSymbol='HONO'
                tokenAddress={HONO_V2_ADDRESS}
                tokenImage='https://app.mangafi.org/hono-icon.svg'
              />
              <AddToMetamask
                textColor='#fff'
                bgColor='#ff164e'
                tokenName='HONO V1'
                tokenSymbol='HONO'
                tokenAddress={HONO_ADDRESS}
                tokenImage='https://app.mangafi.org/hono-icon.svg'
              />
              <AddToMetamask
                textColor='#fff'
                bgColor='#000'
                tokenName='xHONO'
                tokenSymbol='xHONO'
                tokenAddress={xHONO_ADDRESS}
                tokenImage='https://app.mangafi.org/xhono-icon.svg'
              />
            </Box>
          </Box>

          <Box
            position='absolute'
            display='flex'
            alignItems='center'
            gap='0.5rem'
            bottom='2rem'
            width='calc(100% - 1rem)'
            sx={{
              p: theme => theme.spacing(1, 3.75),
              overflow: 'hidden',
              borderRadius: '0 24px 24px 0',
              '&:hover': {
                color: theme => theme.palette.nav.action.hover,
                backgroundColor: theme => theme.palette.nav.background.hover,
                '&::before': {
                  left: 0,
                  top: 0,
                  content: `''`,
                  position: 'absolute',
                  display: 'inline-block',
                  width: '4px',
                  height: '100%',
                  backgroundColor: theme => theme.palette.nav.tick.hover
                }
              },
              cursor: 'pointer'
            }}
          >
            <VerifiedUserIcon />
            <Box
              component='a'
              href='https://github.com/solidproof/projects/blob/main/2023/Manga%20Fi/SmartContract_Audit_Solidproof_MangaFi.pdf'
              target='_blank'
              rel='noreferrer'
              sx={{
                textDecoration: 'none',
                color: 'inherit'
              }}
            >
              Audit Report
            </Box>
          </Box>
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  )
}

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar()
  const { sidebarTheme } = useJumboSidebarTheme()

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open
  }, [sidebarOptions.view, sidebarOptions.open])

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge='start'
                color='inherit'
                aria-label='open drawer'
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  )
}

export default Sidebar
