import { Address, formatEther } from 'viem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick'
// import TrendingDown from '@mui/icons-material/TrendingDown'
// import TrendingUp from '@mui/icons-material/TrendingUp'

import { MintArea } from './MintArea'
import { useBridgeOutputAmount } from 'app/hooks/bridge/useBridge'
import { useHONOPrice } from 'app/hooks/graph/useGraph'
import { formatNumber } from 'app/utils/helper'
import { PageTitle } from 'app/components/PageTitle'

export interface MintProps {
  bridgeAddress: Address
  tokenAddress: Address
  tokenName: string
}

export const Mint = ({ bridgeAddress, tokenAddress, tokenName }: MintProps) => {
  const { data: rate } = useBridgeOutputAmount('1', bridgeAddress)

  const { data: honoPrice } = useHONOPrice()
  const tokenPerHONO = rate ? 1 / +formatEther(rate as unknown as bigint) : 0

  return (
    <Box>
      <PageTitle title={`MangaFi DApp: ${tokenName} to HONO`} />
      <Typography variant='h2' color='text.primary'>
        Mint xHONO
      </Typography>
      <Typography variant='h6' color='text.secondary' mb={3}>
        Conversion of {tokenName} to xHONO is based on a bonding curve. As more {tokenName} gets burned, the rate of
        conversion decreases.
      </Typography>

      <Box
        display='grid'
        sx={{
          gridTemplateColumns: {
            xs: 'repeat(1, minmax(0, 1fr))',
            md: 'repeat(2, minmax(0, 1fr))'
          },
          gap: {
            xs: '1rem',
            md: '2rem'
          }
        }}
        mt='2rem'
      >
        {/* @ts-ignore */}
        <JumboCardQuick
          title={
            <Typography variant={'h2'} color='#000'>
              {honoPrice && tokenPerHONO ? `$${formatNumber(+honoPrice / tokenPerHONO)}` : '...'}
            </Typography>
          }
          subheader={
            <Typography variant={'h6'} mb={0} color='#000'>
              New {tokenName} value based on xHONO's potential
            </Typography>
          }
          headerSx={{ pb: 0 }}
          bgColor={'#ffce22'}
        />

        {/* @ts-ignore */}
        <JumboCardQuick
          title={
            <Typography variant={'h2'} color='#ffce22'>
              {tokenPerHONO ? formatNumber(+tokenPerHONO.toFixed(2)) : '...'} {tokenName}
            </Typography>
          }
          subheader={
            <Typography variant={'h6'} mb={0} color='#ffce22'>
              Current xHONO exchange rate
            </Typography>
          }
          bgColor={'#000'}
        />
      </Box>

      <MintArea
        rate={rate as unknown as bigint}
        bridgeAddress={bridgeAddress}
        tokenName={tokenName}
        tokenAddress={tokenAddress}
      />
    </Box>
  )
}
