import { VoteItem } from './VoteItem'

export const data = [
  {
    name: 'USDC-HONO V2',
    fee: '0.3%',
    poolMinter: '10%',
    logo1: 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
    logo2: '/hono-icon.svg'
  }
]

export const VoteList = () => {
  return data.map((item, index) => <VoteItem vote={item} key={index} />)
}
