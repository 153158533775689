import { useMemo } from 'react'
import { bsc } from 'wagmi/chains'
import { Address } from 'viem'
import LoadingButton from '@mui/lab/LoadingButton'

import { AllowanceGuard } from 'app/components/AllowanceGuard'
import { useBridge } from 'app/hooks/bridge/useBridge'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { NativeBalanceGuard } from 'app/components/NativeBalanceGuard'
import { INUKO_ADDRESS } from 'app/utils/constants/contract'

const MAX_INUKO_AMOUNT_PER_TXN = 25000
export interface MintActionProps {
  amount: string
  fee: string
  bridgeAddress: Address
  tokenAddress: Address
  tokenName: string
}

export const MintAction = ({ amount, fee, bridgeAddress, tokenAddress, tokenName }: MintActionProps) => {
  const { mutate: bridge, isLoading } = useBridge(fee, bridgeAddress)
  const isReachedMaxAmount = useMemo(
    () => tokenAddress === INUKO_ADDRESS && +amount > MAX_INUKO_AMOUNT_PER_TXN,
    [amount, tokenAddress]
  )

  return (
    <ConnectWalletGuard>
      <NetworkGuard
        chain={{
          name: bsc.name,
          id: bsc.id
        }}
      >
        <AllowanceGuard
          tokenAddress={tokenAddress}
          spenderAddress={bridgeAddress}
          tokenName={tokenName}
          amount={amount ?? '0'}
        >
          <NativeBalanceGuard amount={fee ?? '0'} nativeTokenName='BNB'>
            <LoadingButton
              loading={isLoading}
              variant='contained'
              sx={{
                mt: '0.5rem'
              }}
              onClick={() => bridge({ amount })}
              disabled={!amount || isReachedMaxAmount}
            >
              {isReachedMaxAmount ? 'Reached max amount' : 'Mint'}
            </LoadingButton>
          </NativeBalanceGuard>
        </AllowanceGuard>
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
