import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick'
import TextField from '@mui/material/TextField'

import { HONO_ADDRESS } from 'app/utils/constants/contract'
import { MaxAmount } from 'app/components/MaxAmount'
import { formatNumber } from 'app/utils/helper'
import { useBalance } from 'app/hooks/useBalance'
import { PageTitle } from 'app/components/PageTitle'
// import { HONOConverted } from './HONOConverted'
import { UpgradeAction } from './UpgradeAction'

// @todo: update this
export const Upgrade = () => {
  const { data: balanceHONO } = useBalance(HONO_ADDRESS)
  const [amount, setAmount] = useState('')

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }

  const handleChangeMax = (maxAmount: string) => {
    if (maxAmount && balanceHONO) {
      const value = +maxAmount < +balanceHONO ? maxAmount : balanceHONO
      setAmount(value)
    }
  }

  return (
    <div>
      <PageTitle title='MangaFi DApp: xHONO to HONO' />
      <Typography variant='h2' color='text.primary'>
        Upgrade HONO V1 to HONO V2
      </Typography>
      <Typography variant='h6' color='text.secondary' mb={3}>
        Upgrade from HONO v1 to HONO v2 now, HONO v1 has been decommissioned and will no longer grow through MangaFi's
        real yield-generating resources. Moving forward, HONO V2 will be the main growth asset on our platform.
      </Typography>

      <Box>
        {/* @ts-ignore */}
        <JumboCardQuick headerSx={{ pb: 0 }} sx={{ pb: 0 }}>
          <Typography variant={'h2'} textAlign='center'>
            {balanceHONO ? formatNumber(+balanceHONO) : '...'} HONO
          </Typography>
          <Typography variant={'h6'} mb={0} textAlign='center'>
            in your balance
          </Typography>
          <Box mt='2rem' textAlign='center'>
            <Typography variant='h5' color='text.primary'>
              Convert HONO to HONO V2
            </Typography>

            <Box
              mx='auto'
              mb='0.5rem'
              sx={{
                width: {
                  xs: '100%',
                  sm: '360px'
                }
              }}
            >
              <TextField
                label='HONO V2'
                variant='outlined'
                type='number'
                sx={{
                  width: {
                    xs: '100%',
                    sm: '360px'
                  }
                }}
                onChange={handleChangeAmount}
                value={amount}
                InputLabelProps={{ shrink: true }}
              />
              <MaxAmount tokenAddress={HONO_ADDRESS} onChangeAmount={handleChangeMax} />
            </Box>
            <UpgradeAction amount={amount} />
          </Box>
        </JumboCardQuick>

        {/* <Box mt='1rem'>
          <HONOConverted transparentHeader />
        </Box> */}
      </Box>
    </div>
  )
}
