import { Address } from 'viem'
import { useNetwork, useSwitchNetwork, useWalletClient } from 'wagmi'
import { Box, Button } from '@mui/material'

import { MAIN_CHAIN_ID } from 'app/utils/constants/chain'
import { useEffect, useState } from 'react'

export interface AddToMetamaskProps {
  textColor: string
  bgColor: string
  tokenImage: string
  tokenName: string
  tokenSymbol: string
  tokenAddress: Address
}

export const AddToMetamask = ({
  textColor,
  bgColor,
  tokenName,
  tokenSymbol,
  tokenImage,
  tokenAddress
}: AddToMetamaskProps) => {
  const { data: walletClient } = useWalletClient()
  const [shouldPrompt, setShouldPrompt] = useState(false)

  const { chain } = useNetwork()
  const { switchNetwork, isSuccess: switchSuccess } = useSwitchNetwork({
    chainId: MAIN_CHAIN_ID
  })

  const handleAddToken = async () => {
    if (walletClient) {
      try {
        await walletClient.watchAsset({
          type: 'ERC20',
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: 18,
            image: tokenImage
          }
        })

        console.log('Token added to MetaMask')
      } catch (error) {
        console.error('Error adding token to MetaMask:', error)
      } finally {
        setShouldPrompt(false)
      }
    }
  }

  const handleAdd = () => {
    setShouldPrompt(true)
    if (chain?.id !== MAIN_CHAIN_ID) {
      switchNetwork?.()
      return
    }

    handleAddToken()
  }

  useEffect(() => {
    if (switchSuccess && walletClient) {
      if (shouldPrompt) {
        handleAddToken()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchSuccess, walletClient, shouldPrompt])

  return (
    <Button
      variant='contained'
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        color: textColor || '#000',
        bgcolor: bgColor,
        textTransform: 'uppercase',
        borderRadius: '20px'
      }}
      startIcon={<Box component='img' src='/mm-plus-icon.svg' width='24px' />}
      onClick={handleAdd}
    >
      ADD {tokenName}
    </Button>
  )
}
