import Typography from '@mui/material/Typography'

import { LiquidityList } from './LiquidityItem'
import { PageTitle } from 'app/components/PageTitle'

export const Liquidity = () => {
  return (
    <div>
      <PageTitle title='MangaFi DApp: Liquidity' />
      <Typography variant='h2' color='text.primary'>
        Liquidity
      </Typography>
      <Typography variant='body1' color='text.secondary' mb={3}>
        Add or remove your liquidity share in the pools.
      </Typography>

      <LiquidityList />
    </div>
  )
}
