import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'

import { AllowanceGuard } from 'app/components/AllowanceGuard'
import { HONO_V2_ADDRESS, HONO_ADDRESS } from 'app/utils/constants/contract'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { useConvertHONOToHONOV2 } from 'app/hooks/honoV2/useV2'

export interface UpgradeActionProps {
  amount: string
}

export const UpgradeAction = ({ amount }: UpgradeActionProps) => {
  const { mutate: convert, error, isLoading } = useConvertHONOToHONOV2()

  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        <AllowanceGuard
          tokenAddress={HONO_ADDRESS}
          spenderAddress={HONO_V2_ADDRESS}
          tokenName='HONO'
          amount={amount ?? '0'}
        >
          <LoadingButton
            loading={isLoading}
            variant='contained'
            sx={{
              mt: '0.5rem'
            }}
            size={'small'}
            onClick={() => convert({ amount })}
            disabled={!amount}
          >
            Upgrade
          </LoadingButton>
        </AllowanceGuard>
        {!!error && <p style={{ color: 'red' }}>{JSON.stringify(error)}</p>}
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
