import LoadingButton from '@mui/lab/LoadingButton/LoadingButton'

import { AllowanceGuard } from 'app/components/AllowanceGuard'
import { MINT_CURBOND_ADDRESS, USDC_ADDRESS } from 'app/utils/constants/contract'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { useMintX } from 'app/hooks/useMintX'

export interface MintXActionProps {
  amount: string
}

export const MintXAction = ({ amount }: MintXActionProps) => {
  const { mutate: buy, error, isLoading } = useMintX()

  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        <AllowanceGuard
          tokenAddress={USDC_ADDRESS}
          spenderAddress={MINT_CURBOND_ADDRESS}
          tokenName='USDC'
          amount={amount ?? '0'}
        >
          <LoadingButton
            loading={isLoading}
            variant='contained'
            sx={{
              mt: '0.5rem'
            }}
            size={'small'}
            onClick={() => {
              buy({ amount })
            }}
            disabled={!amount}
          >
            Buy CURBOND
          </LoadingButton>
        </AllowanceGuard>
        {!!error && <p style={{ color: 'red' }}>{JSON.stringify(error)}</p>}
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
