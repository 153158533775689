import { ConnectButton } from '@rainbow-me/rainbowkit'
import { useAccount } from 'wagmi'

export const ConnectWalletGuard = ({ children }: { children: React.ReactNode }) => {
  const { address = '' } = useAccount()

  if (!address) {
    return <ConnectButton />
  }

  return children
}
