import { Card, CardContent, Typography } from '@mui/material'

import { useXHONOConverted } from 'app/hooks/useBackPrice'
import { formatNumber } from 'app/utils/helper'

export interface XHONOConvertedProps {
  transparentHeader?: boolean
}

const defaultStyle = {
  pt: '12px',
  pb: '8px',
  textAlign: 'center'
}

export const XHONOConverted = ({ transparentHeader }: XHONOConvertedProps) => {
  const { data } = useXHONOConverted()

  return (
    <Card>
      <CardContent
        sx={{ ...defaultStyle, ...(!transparentHeader && { color: 'common.white', bgcolor: 'rgb(55, 71, 79)' }) }}
      >
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          xHONO converted
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant={'h2'} fontSize={'20px'} fontWeight={500} mt={transparentHeader ? -2.5 : 0}>
          {formatNumber(+(data ?? 0))}
        </Typography>
        <Typography variant={'h3'} fontSize={'16px'} mt={2} mb={3}>
          since 8th August 2023
        </Typography>
        <Typography variant={'h2'} fontSize={'14px'}>
          Amount of xHONO converted to HONO V2 at a 1:1 ratio
        </Typography>
      </CardContent>
    </Card>
  )
}
