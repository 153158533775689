import { useState } from 'react'
import { Address, formatUnits } from 'viem'

import { useContractAllowance } from 'app/hooks/useContractAllowance'
import LoadingButton from '@mui/lab/LoadingButton'
import { useApproveToken } from 'app/hooks/useApproveToken'
import { useDecimals } from 'app/hooks/useBalance'

export interface AllowanceGuardProps {
  tokenAddress: Address
  tokenName: string
  spenderAddress: Address
  children: React.ReactNode
  amount: string
}

export const AllowanceGuard = ({ tokenAddress, tokenName, spenderAddress, amount, children }: AllowanceGuardProps) => {
  const [loading, setLoading] = useState(false)

  const { data: allowance } = useContractAllowance(tokenAddress, spenderAddress)
  const { data: tokenDecimals = 18 } = useDecimals(tokenAddress)

  const { mutateAsync: approveToken } = useApproveToken(tokenAddress)

  const handleApproveToken = async () => {
    setLoading(true)
    const receipt = await approveToken({ spenderAddress })
    setLoading(false)

    return receipt
  }

  if (!allowance || allowance.toString() === '0' || +formatUnits(allowance, tokenDecimals) < +amount) {
    return (
      <LoadingButton loading={loading} variant='contained' onClick={handleApproveToken}>
        {'Approve ' + tokenName}
      </LoadingButton>
    )
  }

  return children
}

export interface LPAllowanceGuardProps {
  tokenAddress: [Address, Address]
  tokenName: [string, string]
  spenderAddress: Address
  children: React.ReactNode
  amount1: string
  amount2: string
}

export const LPAllowanceGuard = ({
  tokenAddress,
  spenderAddress,
  tokenName,
  amount1,
  amount2,
  children
}: LPAllowanceGuardProps) => {
  const [loading, setLoading] = useState(false)

  const { data: allowance1 } = useContractAllowance(tokenAddress[0], spenderAddress)
  const { data: allowance2 } = useContractAllowance(tokenAddress[1], spenderAddress)

  const { data: token1Decimals = 18 } = useDecimals(tokenAddress[0])
  const { data: token2Decimals = 18 } = useDecimals(tokenAddress[1])

  const { mutateAsync: approveToken1 } = useApproveToken(tokenAddress[0])
  const { mutateAsync: approveToken2 } = useApproveToken(tokenAddress[1])

  const handleApproveToken1 = async () => {
    setLoading(true)
    const receipt = await approveToken1({ spenderAddress })
    setLoading(false)

    return receipt
  }

  const handleApproveToken2 = async () => {
    setLoading(true)
    const receipt = await approveToken2({ spenderAddress })
    setLoading(false)

    return receipt
  }

  if (!allowance1 || allowance1.toString() === '0' || +formatUnits(allowance1, token1Decimals) < +amount1) {
    return (
      <LoadingButton loading={loading} variant='contained' onClick={handleApproveToken1}>
        {'Approve ' + tokenName[0]}
      </LoadingButton>
    )
  }

  if (!allowance2 || allowance2.toString() === '0' || +formatUnits(allowance2, token2Decimals) < +amount2) {
    return (
      <LoadingButton loading={loading} variant='contained' onClick={handleApproveToken2}>
        {'Approve ' + tokenName[1]}
      </LoadingButton>
    )
  }

  return children
}
