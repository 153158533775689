import React from 'react'
import Div from '@jumbo/shared/Div'
import Link from '@mui/material/Link'
import { ASSET_IMAGES } from '../../utils/constants/paths'

const Logo = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: 'inline-flex', ...sx }}>
      <Link href={'/'}>
        {!mini ? (
          <img
            src={mode === 'light' ? `${ASSET_IMAGES}/mangafi-logo.png` : `${ASSET_IMAGES}/mangafi-logo.png`}
            alt='MangaFi'
            style={{
              width: 120
            }}
          />
        ) : (
          <img
            src={mode === 'light' ? `${ASSET_IMAGES}/mangafi-logo.png` : `${ASSET_IMAGES}/mangafi-logo.png`}
            alt='MangaFi'
            style={{
              width: 120
            }}
          />
        )}
      </Link>
    </Div>
  )
}

Logo.defaultProps = {
  mode: 'light'
}

export default Logo
