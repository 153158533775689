import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Address } from 'viem'

import Span from '@jumbo/shared/Span'
import { formatNumber } from 'app/utils/helper'
import { ClaimReferrerAction } from './ClaimReferrerAction'
import { useReferrerReward } from 'app/hooks/farm/useFarm'

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1)
}))

export interface ReferrerRewardProps {
  tokenId: number
  lpAddress: Address
}

export const ReferrerReward = ({ tokenId, lpAddress }: ReferrerRewardProps) => {
  const { data, isLoading } = useReferrerReward({ tokenId, lpAddress })

  const referrerEarning = useMemo(() => {
    if (data?.referrerTotalReward && data?.referrerWithdrawReward) {
      return +data.referrerTotalReward - +data.referrerWithdrawReward
    }

    return 0
  }, [data])

  return (
    <Box
      display='flex'
      sx={{
        columnGap: {
          xs: 0,
          md: 6
        },
        flexDirection: {
          xs: 'column',
          lg: 'row'
        },
        alignItems: {
          xs: 'flex-start',
          lg: 'center'
        },
        p: theme => theme.spacing(2, 1),
        mt: {
          xs: 4.5,
          md: 0
        }
      }}
    >
      <Item
        sx={{
          alignSelf: 'flex-start'
        }}
      >
        <Typography variant={'h6'} mt={1} lineHeight={1.25}>
          Referrer Earnings
        </Typography>
        <Typography variant={'body1'} color='text.secondary'>
          {isLoading ? 'loading...' : formatNumber(+referrerEarning)}&nbsp;
        </Typography>
      </Item>
      <Item
        sx={{
          mt: {
            xs: 1,
            md: 0
          }
        }}
      >
        <ClaimReferrerAction amount={`${referrerEarning}`} tokenId={tokenId} lpAddress={lpAddress} />
      </Item>
    </Box>
  )
}
