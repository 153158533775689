import { Button } from '@mui/material'
import { useNativeBalance } from 'app/hooks/useBalance'

export interface NativeBalanceGuardProps {
  children: React.ReactNode
  nativeTokenName: string
  amount: string
}

export const NativeBalanceGuard = ({ children, nativeTokenName, amount }: NativeBalanceGuardProps) => {
  const { data: bnbBalance } = useNativeBalance()

  if (!bnbBalance || +bnbBalance === 0 || +bnbBalance < +amount) {
    return (
      <Button
        sx={{
          mt: '0.5rem'
        }}
        variant='contained'
        disabled
      >
        Not enough {nativeTokenName} for Fee
      </Button>
    )
  }

  return children
}
