import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Address, formatEther, getContract, parseEther } from 'viem'
import { useAccount, useChainId, usePublicClient, useWalletClient } from 'wagmi'
import { waitForTransaction } from 'wagmi/actions'
import toast from 'react-hot-toast'

import { useDynamicContract, useStaticContract } from '../useContract'
import lpABI from 'app/utils/constants/abis/v3LP.json'
import stakingABI from 'app/utils/constants/abis/staking.json'
import { isInternalLP } from 'app/utils/helper'
import { REFRESH_INTERVAL } from 'app/utils/constants/app'

export const useFarmInfo = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const { address = '' } = useAccount()
  const lpContract = useStaticContract(lpAddress, lpABI as any)
  const chainId = useChainId()
  const publicClient = usePublicClient({ chainId })

  const isInternal = isInternalLP(tokenId)

  return useQuery({
    queryKey: ['farm_info', address, tokenId, lpAddress, chainId],
    queryFn: async () => {
      if (lpContract) {
        const stakingAddress = await lpContract.read.nftIdToStakingEngine([tokenId])
        const stakingContract = getContract({
          address: stakingAddress as unknown as Address,
          abi: stakingABI,
          publicClient
        })

        const [userInfo, stakeInfo] = await Promise.all([
          stakingContract.read.userInfo([address]),
          stakingContract.read.stakeInfo([])
        ])

        const [totalReward, amount, rewardDebt, rewardDebtToRealize, rewardToBeRealized, toRealizedCycle] =
          userInfo as bigint[]
        const [lastRewardTime, accETHPerShare, accETHPerShareToBeRealized, startRewardTime] = stakeInfo as bigint[]

        return {
          userInfo: {
            totalReward,
            amount,
            rewardDebt,
            rewardDebtToRealize,
            rewardToBeRealized,
            toRealizedCycle
          },
          stakeInfo: {
            lastRewardTime,
            accETHPerShare,
            accETHPerShareToBeRealized,
            startRewardTime
          }
        }
      }
    },
    enabled: !isInternal && !!lpContract && !!address,
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useHarvest = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const { address = '' } = useAccount()
  const lpContract = useDynamicContract(lpAddress, lpABI as any)
  const client = useQueryClient()
  const chainId = useChainId()

  return useMutation({
    mutationFn: async () => {
      if (lpContract) {
        const hash = await lpContract.write.withdrawReward([tokenId])

        const receipt = await waitForTransaction({
          hash
        })

        return receipt
      }
    },
    onSuccess: () => {
      toast.success('Harvest successfully')
      client.invalidateQueries(['farm_info', address, tokenId, lpAddress, chainId])
    }
  })
}

export const useReferrerReward = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const { address = '' } = useAccount()
  const lpContract = useStaticContract(lpAddress, lpABI as any)
  const publicClient = usePublicClient()
  const chainId = useChainId()

  const isInternal = isInternalLP(tokenId)

  return useQuery({
    queryKey: ['referrer_reward', address, tokenId, lpAddress, chainId],
    queryFn: async () => {
      if (lpContract) {
        const stakingAddress = await lpContract.read.nftIdToStakingEngine([tokenId])
        const stakingContract = getContract({
          address: stakingAddress as unknown as Address,
          abi: stakingABI,
          publicClient
        })

        const [referrerTotalReward, referrerWithdrawReward] = await Promise.all([
          stakingContract.read.referrerTotalReward([address]),
          stakingContract.read.referrerWithdrawReward([address])
        ])

        return {
          referrerTotalReward: formatEther(referrerTotalReward as bigint),
          referrerWithdrawReward: formatEther(referrerWithdrawReward as bigint)
        }
      }
    },
    enabled: !isInternal && !!lpContract && !!address,
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useClaimReferrerReward = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const { address = '' } = useAccount()
  const lpContract = useStaticContract(lpAddress, lpABI as any)
  const client = useQueryClient()
  const { data: walletClient } = useWalletClient()
  const chainId = useChainId()

  return useMutation({
    mutationFn: async (amount: string) => {
      if (lpContract && walletClient) {
        const stakingAddress = await lpContract.read.nftIdToStakingEngine([tokenId])
        const stakingContract = getContract({
          address: stakingAddress as unknown as Address,
          abi: stakingABI,
          walletClient
        })

        const hash = await stakingContract.write.referrerWithdraw([parseEther(amount)])
        const receipt = await waitForTransaction({
          hash
        })

        return receipt
      }
    },
    onSuccess: () => {
      toast.success('Claim referrer rewards successfully')
      client.invalidateQueries(['referrer_reward', address, tokenId, lpAddress, chainId])
    }
  })
}
