import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  TwitterAuthProvider,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendSignInLinkToEmail
} from 'firebase/auth'
import toast from 'react-hot-toast'
import axios from 'axios'

import { auth } from 'app/lib/firebase'

const cloudFunctionUrl = process.env.REACT_APP_FIREBASE_FUNCTION_URL

export const loginWithEmailAndPassword = async ({ email, password }: { email: string; password: string }) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password)

    return res
  } catch (err) {
    console.error(err)
  }
}

export const loginWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider()
    const res = await signInWithPopup(auth, provider)

    return res
  } catch (err) {
    console.error(err)
  }
}

export const loginWithTwitter = async () => {
  try {
    const provider = new TwitterAuthProvider()
    const res = await signInWithPopup(auth, provider)

    return res
  } catch (err) {
    console.error(err)
  }
}

export const logout = async () => {
  try {
    await auth.signOut()
  } catch (err) {
    console.error(err)
  }
}

export const sendEmailLoginLink = async (email: string) => {
  const actionCodeSettings = {
    url: window.location.origin + '/magic-link',
    // This must be true.
    handleCodeInApp: true
  }

  const whitelisted = await axios.post<boolean>(`${cloudFunctionUrl}/whitelisted`, {
    email
  })

  if (!whitelisted.data) {
    toast.error('Email has not whitelisted')
    throw new Error('Email has not whitelisted')
  }

  await sendSignInLinkToEmail(auth, email, actionCodeSettings)
  window.localStorage.setItem('emailForSignIn', email)
}

export const loginWithEmailLoginLink = async () => {
  let email = window.localStorage.getItem('emailForSignIn')
  if (!email) {
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    email = window.prompt('Please provide your email for confirmation')
  }

  const whitelisted = await axios.post<boolean>(`${cloudFunctionUrl}/whitelisted`, {
    email
  })

  if (!whitelisted.data) {
    toast.error('Email has not whitelisted')
    throw new Error('Email has not whitelisted')
  }

  // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(auth, window.location.href)) {
    try {
      const result = await signInWithEmailLink(auth, email || '', window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      return result
    } catch (error) {
      console.log('ahihi error', error)
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
    }
  }
}
