import { Card, CardContent, Typography } from '@mui/material'

import { useTotalSupply } from 'app/hooks/useBalance'
import { xHONO_ADDRESS } from 'app/utils/constants/contract'
import { formatNumber } from 'app/utils/helper'

export const XHONOSupply = () => {
  const { data: XHONOTotalSupply } = useTotalSupply(xHONO_ADDRESS)

  return (
    <Card>
      <CardContent sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: '#37474f' }}>
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          xHONO SUPPLY
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
          {formatNumber(+(XHONOTotalSupply ?? 0))}
        </Typography>
        <Typography variant={'h3'} fontSize={'16px'} mb={3}>
          of 500,000 in circulation
        </Typography>
        <Typography variant={'h2'} fontSize={'14px'}>
          xHONO is added into circulation through conversion or partnership. To convert, click the appropriate
          conversion tab.
        </Typography>
      </CardContent>
    </Card>
  )
}
