import { useCallback } from 'react'
import { Address } from 'viem'

import { useBalance } from 'app/hooks/useBalance'
import { Box } from '@mui/material'
import { formatNumber } from 'app/utils/helper'

export interface MaxAmountProps {}

export const MaxAmount = ({
  tokenAddress,
  onChangeAmount
}: {
  tokenAddress: Address
  onChangeAmount: (amount: string) => void
}) => {
  const { data: balance, isLoading } = useBalance(tokenAddress)

  const handleChangeMax = useCallback(() => {
    return onChangeAmount(balance as unknown as string)
  }, [balance, onChangeAmount])

  if (isLoading) {
    return <Box>loading balance...</Box>
  }

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box>Balance: {balance ? formatNumber(+balance ?? 0) : 0}</Box>
      {balance && +balance > 0 && (
        <Box
          sx={{
            cursor: 'pointer'
          }}
          color='#df752c'
          onClick={handleChangeMax}
        >
          Max
        </Box>
      )}
    </Box>
  )
}
