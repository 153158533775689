import { Button } from '@mui/material'
import { MAIN_CHAIN_ID, MAIN_CHAIN_NAME } from 'app/utils/constants/chain'
import { useNetwork, useSwitchNetwork } from 'wagmi'

export interface NetworkGuardProps {
  chain?: {
    id: number
    name: string
  }
  children: React.ReactNode
}

export const NetworkGuard = ({ children, chain: customChain }: NetworkGuardProps) => {
  const { chain } = useNetwork()
  const correctChainId = customChain?.id || MAIN_CHAIN_ID

  const { switchNetwork } = useSwitchNetwork({
    chainId: correctChainId
  })

  if (chain?.id !== correctChainId) {
    return (
      <Button
        sx={{
          mt: '0.5rem'
        }}
        variant='contained'
        color='secondary'
        onClick={() => switchNetwork?.()}
      >
        Switch To {customChain?.name || MAIN_CHAIN_NAME}
      </Button>
    )
  }

  return children
}
