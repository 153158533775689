import { useMemo } from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'

import { ProgressBar } from './ProgressBar'
import { useHONOHolders } from 'app/hooks/useBackPrice'
import { formatNumber } from 'app/utils/helper'

const target = 1500
export const CommunityGoal = () => {
  const { data: holders = 0 } = useHONOHolders()
  const percent = useMemo(() => {
    if (holders) {
      return (holders / target) * 100
    }

    return 0
  }, [holders])

  return (
    <Card>
      <CardContent
        sx={{
          backgroundImage: 'linear-gradient(#ffce22, #df752c)',
          backgroundRepeat: 'repeat-y',
          pt: '18px',
          pb: '26px'
        }}
      >
        <Typography variant={'h2'} fontSize={22} fontWeight={500} color='#000'>
          Community Goal: Help us reach {formatNumber(target)} holders*
        </Typography>
        <Typography variant={'h2'} fontSize={16.5} fontWeight={500} my={2.5} color='#fff'>
          Reward: $10,000 of HONO V2 into the xHONO conversion pool
        </Typography>

        <Box my='1.25rem'>
          <Box mb='0.25rem' fontSize={20} color='#fff'>
            PROGRESS | {percent.toFixed(2)}%
          </Box>
          <ProgressBar value={percent} />
        </Box>

        <Typography
          variant={'h3'}
          fontSize={15}
          sx={{
            wordBreak: 'break-word'
          }}
          color='#fff'
        >
          *NOTE: Only addresses that hold at least 1 HONO V2 qualify
        </Typography>
      </CardContent>
    </Card>
  )
}
