import { useState } from 'react'
import { Address } from 'viem'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import toast from 'react-hot-toast'

import { useLockPool } from 'app/hooks/exchange/useExchange'
import { MaxAmount } from 'app/components/MaxAmount'
import { LOCK_POOL_ADDRESS } from 'app/utils/constants/contract'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { AllowanceGuard } from 'app/components/AllowanceGuard'
import { VoteType } from './VoteItem'

export interface VoteActionProps {
  tokenAddress: Address
  lockPool: VoteType
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  margin: '0 auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 6,
  textAlign: 'center' as 'center'
}

export const VoteAction = ({ lockPool, tokenAddress }: VoteActionProps) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [amount, setAmount] = useState('')

  const toggleOpen = () => {
    setOpen(state => !state)
  }

  const { mutateAsync: vote } = useLockPool(lockPool)

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }

  const handleVote = async () => {
    try {
      setLoading(true)
      await vote({
        amount
      })
      toggleOpen()
    } catch (err) {
      toast.error(JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        <AllowanceGuard
          tokenAddress={tokenAddress}
          spenderAddress={LOCK_POOL_ADDRESS}
          tokenName='xHONO'
          amount={amount ?? 0}
        >
          <LoadingButton loading={loading} variant={'outlined'} size={'small'} onClick={toggleOpen}>
            Vote
          </LoadingButton>

          <Modal open={open} onClose={toggleOpen} aria-labelledby='vote-modal-title'>
            <Box display='flex' flexDirection='column' alignItems='center' sx={style}>
              <Typography id='vote-modal-title' variant='h4' component='h2'>
                Vote Amount
              </Typography>
              <Box
                sx={{
                  width: '320px',
                  mb: '1rem'
                }}
              >
                <TextField
                  label='xHONO'
                  variant='outlined'
                  type='number'
                  sx={{
                    width: '100%'
                  }}
                  onChange={handleChangeAmount}
                  value={amount}
                  InputLabelProps={{ shrink: true }}
                />
                <MaxAmount tokenAddress={tokenAddress} onChangeAmount={setAmount} />
              </Box>

              <LoadingButton
                loading={loading}
                variant={'outlined'}
                size={'small'}
                disabled={!amount}
                onClick={handleVote}
              >
                Vote Pool
              </LoadingButton>
            </Box>
          </Modal>
        </AllowanceGuard>
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
