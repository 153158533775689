import React from 'react'
import { Accordion, AccordionDetails, Typography, Box } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionSummary from '@mui/material/AccordionSummary'
import Avatar from '@mui/material/Avatar'
import Div from '@jumbo/shared/Div'

import { LiquidityItemDetail } from './LiquidityItemDetail'
import { HONOV2_USDC_TOKEN_ID, pairs } from 'app/utils/constants/contract'
import { PairItem } from 'app/types/LP'
import { usePoolBalance, usePoolInfo } from 'app/hooks/liquidity/useLiquidityPool'
import { formatNumber } from 'app/utils/helper'
import { useHonoPoolInfo } from 'app/hooks/graph/useGraph'

export const LiquidityList = () => {
  return (
    <Box display='flex' flexDirection='column' rowGap='8px'>
      {Object.values(pairs)
        .filter(item => !item.internal)
        .map(item => (
          <LiquidityItem item={item as PairItem} key={item.tokenId} />
        ))}
    </Box>
  )
}

export const LiquidityItem = ({
  item,
  expanded,
  handleChange
}: {
  item: PairItem
  expanded?: boolean
  handleChange?: () => void
}) => {
  const { data: poolInfo, isLoading } = usePoolInfo({ tokenId: item.tokenId, lpAddress: item.lpAddress })
  const { data: balance, isLoading: loadingBalance } = usePoolBalance(item.lpAddress, item.tokenId)

  const { data: honoPoolInfo } = useHonoPoolInfo()
  const isHonoUSDCPool = item.tokenId === HONOV2_USDC_TOKEN_ID

  const userBalancePercentageInPool = balance && honoPoolInfo ? +balance / +honoPoolInfo.liquidity : 0
  const honoV2Value =
    !!userBalancePercentageInPool && honoPoolInfo ? userBalancePercentageInPool * +honoPoolInfo.totalHONO : 0
  const usdcValue =
    !!userBalancePercentageInPool && honoPoolInfo ? userBalancePercentageInPool * +honoPoolInfo.totalUSDC : 0

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        square
        sx={{
          borderRadius: 2,
          padding: {
            xl: '0 16px'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center'
            }
          }}
        >
          <Div sx={{ display: 'flex', flexShrink: 0, px: 1 }}>
            <Avatar sx={{ width: 28, height: 28 }} alt={item.name.split('-')[0]} src={item.logo1} variant='rounded' />
            <Avatar sx={{ width: 28, height: 28 }} alt={item.name.split('-')[1]} src={item.logo2} variant='rounded' />
          </Div>

          <Box
            display='flex'
            sx={{
              columnGap: {
                xs: '1rem',
                md: '3rem',
                xl: '6rem'
              }
            }}
          >
            <Box width='12rem'>
              <Typography variant={'h5'} mb={0.5}>
                {item.name}
              </Typography>
              <Box mt='0.25rem'>
                {loadingBalance
                  ? 'loading balance...'
                  : balance
                  ? `Your balance: ${formatNumber(+balance)}`
                  : `Your balance: 0`}
              </Box>

              {!loadingBalance && isHonoUSDCPool && balance && +balance > 0 && (
                <Box fontSize={12}>
                  <Box>HONO V2: {formatNumber(+honoV2Value, 6)}</Box>
                  <Box>USDC: {formatNumber(+usdcValue, 6)}</Box>
                </Box>
              )}
            </Box>
            <Box>
              <Typography fontSize={'12px'} variant={'h6'} color={'text.secondary'} mb={0.25}>
                Fee
              </Typography>
              <Typography variant={'body1'}>
                {isLoading ? 'loading...' : poolInfo?.fee ? `${+poolInfo?.fee / 10000}%` : 'N/A'}
              </Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            p: {
              xl: '1rem'
            }
          }}
        >
          {isLoading ? (
            `Loading pool information...`
          ) : (
            <LiquidityItemDetail
              item={item}
              ratio={poolInfo?.ratio || '1'}
              poolBalance={balance || ''}
              tickLower={poolInfo?.tickLower || 1}
              tickUpper={poolInfo?.tickUpper || 1}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}
