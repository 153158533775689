import { Address } from 'viem'
import LoadingButton from '@mui/lab/LoadingButton'

import { useHarvest } from 'app/hooks/farm/useFarm'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'

export interface HarvestActionProps {
  tokenId: number
  lpAddress: Address
}

export const HarvestAction = ({ tokenId, lpAddress }: HarvestActionProps) => {
  const { mutate: harvest, isLoading } = useHarvest({
    tokenId,
    lpAddress
  })
  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        <LoadingButton
          loading={isLoading}
          sx={{ minWidth: 92 }}
          variant={'contained'}
          size={'small'}
          onClick={() => harvest()}
        >
          Harvest ETH
        </LoadingButton>
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
