import { useMemo } from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { useInitHONOPrice } from 'app/hooks/graph/useGraph'

const initHONOPrice = 1 / 183

export const RateOfIncrease = () => {
  const { data: currentHONOPrice } = useInitHONOPrice()

  const rate = useMemo(() => {
    if (!currentHONOPrice) return 0
    return ((currentHONOPrice - initHONOPrice) / initHONOPrice) * 100
  }, [currentHONOPrice])

  return (
    <Card>
      <CardContent sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: '#ff164e' }}>
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          HONO V2 GROWTH PER ANNUM
        </Typography>
      </CardContent>
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
          {rate.toFixed(3)}%
        </Typography>
        <Typography variant={'h3'} fontSize={'16px'} mb={3}>
          since 8th August 2023
        </Typography>
        <Typography variant={'h2'} fontSize={'14px'}>
          Growth is calculated as (difference between launch & current price) / launch price
        </Typography>
      </CardContent>
    </Card>
  )
}
