import { useAccount } from 'wagmi'
import { Address } from 'viem'
import { useQuery } from '@tanstack/react-query'

import { useTokenContract } from './useContract'

export const contractAllowanceQueryKey = (address: Address | '', tokenAddress: Address, contractAddress: Address) => [
  'useContractAllowances',
  address,
  tokenAddress,
  contractAddress
]

export const useContractAllowance = (tokenAddress: Address, contractAddress: Address) => {
  const token = useTokenContract(tokenAddress)
  const { address = '', isConnected } = useAccount()

  return useQuery(
    contractAllowanceQueryKey(address, tokenAddress, contractAddress),
    async () => {
      if (!token) {
        console.warn("Token was expected to exist on current network, but didn't.")
        return null
      }
      if (!address) throw new Error("Address doesn't exist on current network")
      if (!contractAddress) throw new Error("Contract doesn't exist on current network")

      return token.read.allowance([address, contractAddress])
    },
    { enabled: !!address && !!isConnected }
  )
}
