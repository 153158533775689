import React from 'react'
import { Accordion, AccordionDetails, Box, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionSummary from '@mui/material/AccordionSummary'

import { MintX } from 'app/pages/mint/MintX'
import { CURBOND_ADDRESS, MINT_CURBOND_ADDRESS } from 'app/utils/constants/contract'
import { ProgressBar } from '../home/CommunityGoal/ProgressBar'
import { useBalanceOf } from 'app/hooks/useBalance'

const totalSupply = 8000

export const CrowdfundingList = () => {
  return (
    <Box display='flex' flexDirection='column' rowGap='8px'>
      {Array.from(['CURBOND'])
        .filter(item => item)
        .map(item => (
          <CrowdfundingItem />
        ))}
    </Box>
  )
}

export const CrowdfundingItem = ({ expanded, handleChange }: { expanded?: boolean; handleChange?: () => void }) => {
  const { data: balanceOfCurbond } = useBalanceOf({
    tokenAddress: CURBOND_ADDRESS,
    contractAddress: MINT_CURBOND_ADDRESS
  })

  const currentSupply = totalSupply - (balanceOfCurbond ? +balanceOfCurbond : totalSupply)

  return (
    <React.Fragment>
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        square
        sx={{
          borderRadius: 2,
          padding: {
            xl: '0 16px'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center'
            }
          }}
        >
          <div
            style={{
              width: '99%',
              paddingTop: '0.25rem'
            }}
          >
            <Typography variant='h4' color='text.primary'>
              Curation Entertainment
            </Typography>
            <ProgressBar value={(currentSupply / totalSupply) * 100} />
            <div
              style={{
                marginTop: '2px',
                fontWeight: 500
              }}
            >{`${currentSupply.toFixed(2)} / ${totalSupply}`}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <MintX />
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}
