import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Address, getContract, parseEther, parseUnits } from 'viem'
import { useAccount, useChainId, usePublicClient } from 'wagmi'
import { waitForTransaction } from 'wagmi/actions'
import toast from 'react-hot-toast'

import { useDynamicContract, useStaticContract } from '../useContract'
import lpABI from 'app/utils/constants/abis/v3LP.json'
import lpOriginABI from 'app/utils/constants/abis/v3LPOrigin.json'
import posMgrABI from 'app/utils/constants/abis/v3PosMgr.json'
import factoryABI from 'app/utils/constants/abis/v3Factory.json'
import poolABI from 'app/utils/constants/abis/v3Pool.json'
import { isInternalLP } from 'app/utils/helper'
import { pairs } from 'app/utils/constants/contract'
import { REFRESH_INTERVAL } from 'app/utils/constants/app'

export interface AddLPParams {
  tokenId: number
  amountAdd1: string
  amountAdd2: string
  slippage: string
  referrer: Address
}

export interface RemoveLPParams {
  tokenId: number
  liquidity: string
  slippage: string
}

export const useAddLP = (lpAddress: Address, tokenId: number) => {
  const lpContract = useDynamicContract(lpAddress, lpABI as any)
  const client = useQueryClient()
  const chainId = useChainId()
  const { address = '' } = useAccount()

  const currentPair = Object.values(pairs).find(pair => pair.tokenId === tokenId)
  const [token1, token2] = currentPair?.name?.split('-') || []
  const isToken1StableCoin = token1?.includes('USD')
  const isToken2StableCoin = token2?.includes('USD')

  return useMutation({
    mutationFn: async ({ tokenId, amountAdd1, amountAdd2, slippage, referrer }: AddLPParams) => {
      if (lpContract) {
        const hash = await lpContract.write.increaseLiquidityCurrentRange([
          tokenId,
          isToken1StableCoin ? parseUnits(amountAdd1, 6) : parseEther(amountAdd1),
          isToken2StableCoin ? parseUnits(amountAdd2, 6) : parseEther(amountAdd2),
          +slippage * 10000 + '',
          referrer
        ])
        await waitForTransaction({
          hash
        })
      }
    },
    onSuccess: () => {
      toast.success('Add LP successfully')
      client.invalidateQueries({
        queryKey: ['pool_balance', address, tokenId, lpAddress, chainId]
      })
      client.invalidateQueries({
        queryKey: ['user_balance', address]
      })
    }
  })
}

export const useRemoveLP = (lpAddress: Address, tokenId: number) => {
  const client = useQueryClient()
  const { address = '' } = useAccount()
  const chainId = useChainId()

  const lpContract = useDynamicContract(lpAddress, lpABI as any)

  return useMutation({
    mutationFn: async ({ tokenId, liquidity, slippage }: RemoveLPParams) => {
      if (lpContract) {
        const hash = await lpContract.write.decreaseLiquidity([tokenId, liquidity, +slippage * 10000 + ''])
        await waitForTransaction({
          hash
        })
      }
    },
    onSuccess: () => {
      toast.success('Remove LP successfully')
      client.invalidateQueries({
        queryKey: ['pool_balance', address, tokenId, lpAddress, chainId]
      })
      client.invalidateQueries({
        queryKey: ['user_balance', address]
      })
    }
  })
}

export const usePoolInfo = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const lpContract = useStaticContract(lpAddress, lpABI as any)
  const publicClient = usePublicClient()
  const chainId = useChainId()

  return useQuery({
    queryKey: ['pool_info', tokenId, lpAddress, chainId],
    queryFn: async () => {
      if (lpContract) {
        const posMgrAddress = await lpContract.read._posMgr([])
        const posMgrContract = getContract({
          address: posMgrAddress as unknown as Address,
          abi: posMgrABI,
          publicClient
        })

        const [positions, factoryAddress] = await Promise.all([
          posMgrContract.read.positions([tokenId]),
          posMgrContract.read.factory([])
        ])

        const [, , addressToken1, addressToken2, fee, tickLower, tickUpper, , , , ,] = positions as any[] // 2 is token 1 address, 3 is token 2 address, 4 is fee

        const factoryContract = getContract({
          address: factoryAddress as unknown as Address,
          abi: factoryABI,
          publicClient
        })
        const poolAddress = await factoryContract.read.getPool([addressToken1, addressToken2, fee])

        const poolContract = getContract({
          address: poolAddress as unknown as Address,
          abi: poolABI,
          publicClient
        })

        const [sqrtPriceX96, , , liquidity, , , ,] = (await poolContract.read.slot0([])) as any[]

        //@ts-ignore
        const ratio = Number(sqrtPriceX96) / 2 ** 96

        // @todo: update here base on position of token 1, if token 1 is stable then format 6, if token
        return { poolAddress, fee, ratio: ratio.toString(), liquidity, tickLower, tickUpper }
      }
    },
    enabled: !!lpContract,
    refetchInterval: REFRESH_INTERVAL
  })
}

export const usePoolBalance = (lpAddress: Address, tokenId: number) => {
  const lpContract = useStaticContract(lpAddress, lpABI as any)
  const lpInternalContract = useStaticContract(lpAddress, lpOriginABI as any)
  const { address = '' } = useAccount()
  const chainId = useChainId()

  const isInternal = isInternalLP(tokenId)

  return useQuery({
    queryKey: ['pool_balance', address, tokenId, lpAddress, chainId],
    queryFn: async () => {
      if (lpContract && address) {
        const balance = isInternal
          ? await lpInternalContract.read.balanceOf([address])
          : await lpContract.read.balanceOf([address, tokenId])
        return balance.toString()
      }
    },
    enabled: !!lpContract && !!address,
    refetchInterval: REFRESH_INTERVAL
  })
}

export const usePoolAddress = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const lpContract = useStaticContract(lpAddress, lpABI as any)
  const publicClient = usePublicClient()
  const chainId = useChainId()

  return useQuery({
    queryKey: ['pool_address', lpAddress, chainId],
    queryFn: async () => {
      if (lpContract) {
        const posMgrAddress = await lpContract.read._posMgr([])
        const posMgrContract = getContract({
          address: posMgrAddress as unknown as Address,
          abi: posMgrABI,
          publicClient
        })

        const [positions, factoryAddress] = await Promise.all([
          posMgrContract.read.positions([tokenId]),
          posMgrContract.read.factory([])
        ])

        const [, , addressToken1, addressToken2, fee, , , , , , ,] = positions as any[] // 2 is token 1 address, 3 is token 2 address, 4 is fee

        const factoryContract = getContract({
          address: factoryAddress as unknown as Address,
          abi: factoryABI,
          publicClient
        })
        const poolAddress = await factoryContract.read.getPool([addressToken1, addressToken2, fee])

        return poolAddress
      }
    },
    enabled: !!lpContract
  })
}
