import { useETHPrice, useHONOPrice } from 'app/hooks/graph/useGraph'

export interface HomeLabelProps {}

export const HomeLabel = (props: HomeLabelProps) => {
  const { data: honoPrice } = useHONOPrice()
  const { data: ethPrice } = useETHPrice()

  return <div>{`HONO V2: ${(honoPrice && ethPrice ? +honoPrice / +ethPrice : 0).toFixed(10)} ETH`}</div>
}
