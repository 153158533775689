import format from 'date-fns/format'

import { pairs } from './constants/contract'

export const formatCurrency = (value: number, maxDigit = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: maxDigit
  }).format(value)
}

export const formatSmallNumber = (value: number) => {
  return value.toFixed(10)
}

export const formatNumber = (value: number, maxDigit?: number) => {
  if (value === 0) {
    return value
  }
  if (value < 1) {
    if (maxDigit) {
      return value.toFixed(maxDigit)
    }

    return value
  }
  return new Intl.NumberFormat('en-US').format(value)
}

export const formatDate = (date: Date) => {
  return format(date, 'MM/dd/yyyy')
}

export const formatDateTime = (date: Date) => {
  return format(date, 'MM/dd/yyyy HH:mm')
}

export const isInternalLP = (tokenId: number) => {
  const lp = Object.values(pairs).find(p => p.tokenId === tokenId)

  return lp?.internal
}

export const setStorage = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

export const getStorage = (key: string) => {
  return localStorage.getItem(key)
}

export const removeStorage = (key: string) => {
  localStorage.removeItem(key)
}
