import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useAccount, erc20ABI, useChainId, usePublicClient } from 'wagmi'
import { Address, formatEther, formatUnits } from 'viem'

import { useStaticContract } from 'app/hooks/useContract'
import { REFRESH_INTERVAL } from 'app/utils/constants/app'
import { sc } from 'app/utils/constants/contract'

export const balanceQueryKey = (address: Address | '', tokenAddress: Address, chainId: number) => [
  'user_balance',
  address,
  tokenAddress,
  chainId
]

export const useBalance = (tokenAddress: Address) => {
  const tokenContract = useStaticContract(tokenAddress, erc20ABI)
  const { address = '' } = useAccount()
  const chainId = useChainId()

  return useQuery({
    queryKey: balanceQueryKey(address, tokenAddress, chainId),
    queryFn: async () => {
      if (tokenContract && address) {
        const [decimals, balance] = await Promise.all([
          tokenContract.read.decimals(),
          tokenContract.read.balanceOf([address])
        ])

        return formatUnits(balance as unknown as bigint, +decimals.toString())
      }
    },
    enabled: !!tokenContract && !!address,
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useBalanceOf = ({
  tokenAddress,
  contractAddress
}: {
  tokenAddress: Address
  contractAddress: Address
}) => {
  const tokenContract = useStaticContract(tokenAddress, erc20ABI)
  const chainId = useChainId()

  return useQuery({
    queryKey: balanceQueryKey(contractAddress, tokenAddress, chainId),
    queryFn: async () => {
      if (tokenContract && contractAddress) {
        const [decimals, balance] = await Promise.all([
          tokenContract.read.decimals(),
          tokenContract.read.balanceOf([contractAddress])
        ])

        return formatUnits(balance as unknown as bigint, +decimals.toString())
      }
    },
    enabled: !!tokenContract && !!contractAddress,
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useTotalSupply = (tokenAddress: Address) => {
  const tokenContract = useStaticContract(tokenAddress, erc20ABI)
  const chainId = useChainId()

  return useQuery({
    queryKey: ['total_supply', tokenAddress, chainId],
    queryFn: async () => {
      if (tokenContract) {
        const totalSupply = await tokenContract.read.totalSupply()

        return formatEther(totalSupply as unknown as bigint)
      }
    },
    enabled: !!tokenContract
  })
}

export const useDecimals = (tokenAddress: Address) => {
  const tokenContract = useStaticContract(tokenAddress, erc20ABI)
  const chainId = useChainId()

  return useQuery({
    queryKey: ['decimals', tokenAddress, chainId],
    queryFn: async () => {
      if (tokenContract) {
        const decimals = await tokenContract.read.decimals()

        return +decimals.toString()
      }
    },
    enabled: !!tokenContract
  })
}

export const useNativeBalance = () => {
  const provider = usePublicClient()
  const { address = '' } = useAccount()
  const chainId = useChainId()

  return useQuery({
    queryKey: ['native_balance', address, chainId],
    queryFn: async () => {
      if (address) {
        const nativeBalance = await provider.getBalance({
          address
        })

        return formatEther(nativeBalance)
      }
    },
    enabled: !!address
  })
}

export const useNativeBalanceOf = (address: Address) => {
  const provider = usePublicClient()
  const chainId = useChainId()

  return useQuery({
    queryKey: ['native_balance_of', address, chainId],
    queryFn: async () => {
      if (address) {
        const nativeBalance = await provider.getBalance({
          address
        })

        return formatEther(nativeBalance)
      }
    },
    enabled: !!address
  })
}

export const useEncryptAddress = () => {
  const { address: originAddress = '' } = useAccount()

  return useMemo(() => sc.encryptToQRCodeAlphanumeric(originAddress), [originAddress])
}

export const useDecryptAddress = (address: string) => {
  return useMemo(() => {
    const utf8Decoder = new TextDecoder('utf-8', { fatal: true })
    const decryptedAddress = sc.decryptQRCodeAlphanumeric(address)
    if (!decryptedAddress) return '' as unknown as Address

    return utf8Decoder.decode(decryptedAddress) as unknown as Address
  }, [address])
}
