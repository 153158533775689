import { useMemo } from 'react'
import { formatEther } from 'viem'
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import uniqBy from 'lodash/uniqBy'
import { capitalizeFLetter } from '@jumbo/utils'
import Div from '@jumbo/shared/Div'
import { BackPrice, BackPrice2 } from 'app/hooks/useBackPrice'
import { formatDate } from 'app/utils/helper'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { ChartTitle } from './ChartTitle'

export interface TotalBackingChartProps {
  backPrices: BackPrice[]
  backPrices2: BackPrice2[]
}

export const TotalBackingChart = ({ backPrices, backPrices2 }: TotalBackingChartProps) => {
  const backPriceFormat: Omit<BackPrice2, 'WSTETHPerHONO'>[] = useMemo(() => {
    return backPrices.map(item => ({
      ...item,
      ETHPerHONO: item.HonoPerFancoin
    }))
  }, [backPrices])

  const chartData1 = uniqBy(backPriceFormat, item => {
    const date = formatDate(new Date(+item.blockTimeStamp * 1000))

    return date
  })
    .sort((a, b) => Number(a.blockTimeStamp) - Number(b.blockTimeStamp))
    .map(item => {
      return {
        date: formatDate(new Date(+item.blockTimeStamp * 1000)),
        price: parseFloat(formatEther(item?.ETHPerHONO as unknown as bigint)).toFixed(9)
      }
    })

  const chartData2 = uniqBy(backPrices2, item => {
    const date = formatDate(new Date(+item.blockTimeStamp * 1000))

    return date
  })
    .sort((a, b) => Number(a.blockTimeStamp) - Number(b.blockTimeStamp))
    .map(item => {
      if (!item?.ETHPerHONO)
        return {
          data: formatDate(new Date(+item.blockTimeStamp * 1000)),
          price: 0
        }

      const [integerPart] = item?.ETHPerHONO?.split('.')
      return {
        date: formatDate(new Date(+item.blockTimeStamp * 1000)),
        price: formatEther(BigInt(integerPart))
      }
    })

  const chartData = useMemo(() => [...chartData1, ...chartData2], [chartData1, chartData2])

  const itemsLength = useMemo(() => chartData.length * 60, [chartData])
  const chartWidth = useMemo(() => {
    if (chartData.length >= 11) {
      const viewportWidth = window.innerWidth - 260
      return Math.max(viewportWidth, itemsLength)
    }

    return '100%'
  }, [chartData, itemsLength])

  return (
    // @ts-ignore
    <JumboCardQuick
      title={<ChartTitle />}
      noWrapper
      bgColor={'#2B3361'}
      sx={{
        color: 'common.white',
        borderRadius: 0,
        mx: { xs: -4, lg: -6 }
      }}
    >
      <div
        style={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        <ResponsiveContainer height={380} width={chartWidth}>
          <AreaChart data={chartData} margin={{ top: 10, right: 0, left: 12, bottom: 0 }}>
            <defs>
              <linearGradient id='colorLastMonthSales' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#b29af4' />
                <stop offset='95%' stopColor='#ffce22' />
              </linearGradient>
            </defs>
            <Tooltip
              animationEasing={'ease-in-out'}
              content={({ active, label, payload }: any) => {
                return active ? (
                  <Div sx={{ color: 'common.white' }}>
                    {payload?.map((row: any, index: any) => {
                      return (
                        <div key={index} className={index !== payload.length - 1 ? 'mb-1' : ''}>
                          <div
                            style={{
                              color: '#fff',
                              fontSize: 8,
                              letterSpacing: 2,
                              textTransform: 'uppercase'
                            }}
                          >
                            {capitalizeFLetter(row.name)}
                          </div>
                          <div
                            style={{
                              color: '#fff'
                            }}
                          >
                            {row.value} ETH
                          </div>
                        </div>
                      )
                    })}
                  </Div>
                ) : null
              }}
              wrapperStyle={{
                background: 'rgba(0,0,0,0.8)',
                borderRadius: 4,
                padding: '5px 8px',
                fontWeight: 500,
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
              }}
              cursor={false}
            />
            <CartesianGrid strokeDasharray='6 1 2' horizontal={false} strokeOpacity={0.7} />
            <XAxis dataKey='date' tick={{ fill: '#fff', dy: 16 }} height={50} />
            <YAxis
              dataKey='price'
              label={{
                value: 'HONO V2 Price in ETH',
                angle: -90,
                dy: 50,
                position: 'insideLeft',
                fill: '#fff'
              }}
              tick={{ fill: '#fff' }}
              domain={['dataMin', 'dataMax']}
              width={110}
              interval={0}
              allowDataOverflow={true}
            />
            <Area
              type={'monotone'}
              dataKey='price'
              strokeWidth={2}
              stackId='2'
              stroke='#FFf'
              fill='#ffce22'
              fillOpacity={0.7}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </JumboCardQuick>
  )
}
