import { useMemo } from 'react'
import { getContract, Address } from 'viem'
import { erc20ABI, useChainId, usePublicClient, useWalletClient } from 'wagmi'
import { Abi } from 'abitype'

export const useTokenContract = (address: Address) => {
  const publicClient = usePublicClient()

  return useMemo(
    () =>
      getContract({
        address,
        abi: erc20ABI,
        publicClient
      }),
    [address, publicClient]
  )
}

export const useStaticContract = <T extends Abi>(address: Address, abi: T) => {
  const chainId = useChainId()
  const publicClient = usePublicClient({
    chainId
  })

  return useMemo(
    () =>
      getContract({
        address,
        abi,
        publicClient
      }),
    [address, abi, publicClient]
  )
}

export const useDynamicContract = <T extends Abi>(address: Address, abi: T) => {
  const { data: walletClient } = useWalletClient()

  return useMemo(() => {
    if (!address || !walletClient) return null

    return getContract({
      address,
      abi,
      walletClient
    })
  }, [address, abi, walletClient])
}
