import { useQuery } from '@tanstack/react-query'
import { priceFile, tvlFile } from 'app/utils/constants/contract'

export const getTVL = async () => {
  const res = await fetch(tvlFile).then(res => res.json())
  return res as {
    honoUsdc: number
    ethLink: number
  }
}

export const getPrice = async () => {
  const res = await fetch(priceFile).then(res => res.json())
  return res as {
    honoPrice: number
    ethPrice: number
    linkPrice: number
    stakingRate: number
    honoPoolInfo: {
      totalHONO: string
      totalUSDC: string
      liquidity: string
    }
  }
}

export const useGraphDataFromFile = () => {
  return useQuery<{
    honoUsdc: number
    ethLink: number
    honoPrice: number
    ethPrice: number
    linkPrice: number
    stakingRate: number
  }>({
    queryKey: ['graphDataFromFile'],
    queryFn: async () => {
      const [tvl, price] = await Promise.all([getTVL(), getPrice()])

      return {
        ...tvl,
        ...price
      }
    }
  })
}
