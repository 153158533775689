import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

export const firebaseConfig = {
  apiKey: 'AIzaSyBfWoGUfkYBjrCHQwnVbecmR0pTLrJUT9o',
  authDomain: 'mangafi-production.firebaseapp.com',
  projectId: 'mangafi-production',
  storageBucket: 'mangafi-production.appspot.com',
  messagingSenderId: '582186022790',
  appId: '1:582186022790:web:81e068572a1470a6c61e07'
}

const app = initializeApp(firebaseConfig)
//type the auth and firestore functions
const auth = getAuth()
const firestore = getFirestore(app)
const storage = getStorage(app)

export { auth, firestore, storage }
