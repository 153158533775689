import { useQuery } from '@tanstack/react-query'
// import { request, gql } from 'graphql-request'
import dateDiff from 'date-fns/differenceInCalendarDays'
import { Address, formatEther } from 'viem'

import { HONO_V2_ADDRESS } from 'app/utils/constants/contract'
import { DEPLOYED_TIME, REFRESH_INTERVAL } from 'app/utils/constants/app'
import { useFarmInfo } from '../farm/useFarm'
import { useTotalSupply } from '../useBalance'
import { useETHBalanceInHONOV2 } from '../useBackPrice'
import { getPrice } from '../useGraphDataFromFile'

// export const HONO_USDC_PoolAddress = '0xe7871750223ab1a1a05c61fe6beb0c688788aef1'
// const WETH_USDC_PoolAddress = '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640'
// const LINK_USDC_PoolAddress = '0xfad57d2039c21811c8f2b5d5b65308aa99d31559'

export interface HONOPriceGraph {
  sqrtPrice: string
  id: string
  token0: { id: string }
  token1: { id: string }
  token0Price: string
  token1Price: string
  totalValueLockedUSD: string
  totalValueLockedToken0: string
  totalValueLockedToken1: string
}

export interface StakingRateGraph extends HONOPriceGraph {
  totalValueLockedToken1: string
  totalValueLockedToken0: string
  totalValueLockedETH: string
  liquidity: string
}

export const useHONOPrice = () => {
  return useQuery({
    queryKey: ['HONO_price'],
    queryFn: async () => {
      const price = await getPrice()

      return price.honoPrice
    }
  })
}

export const useETHPrice = () => {
  return useQuery({
    queryKey: ['ETH_price'],
    queryFn: async () => {
      const price = await getPrice()

      return price.ethPrice
    }
  })
}

export const useLinkPrice = () => {
  return useQuery({
    queryKey: ['Link_price'],
    queryFn: async () => {
      const price = await getPrice()

      return price.linkPrice
    }
  })
}

export const useLPStakingRate = ({ tokenId, lpAddress }: { tokenId: number; lpAddress: Address }) => {
  const { data } = useFarmInfo({ tokenId, lpAddress })
  const accETHPerShare = data?.stakeInfo?.accETHPerShare
  const startRewardTime = DEPLOYED_TIME

  return useQuery({
    queryKey: ['LP_staking_rate', data?.stakeInfo?.accETHPerShare?.toString(), startRewardTime],
    queryFn: async () => {
      if (!accETHPerShare) {
        return 0
      }

      const price = await getPrice()
      const b = price.stakingRate
      const datePast = dateDiff(new Date(), new Date(startRewardTime))

      return ((+formatEther(accETHPerShare) * 365) / (b * datePast) / 10 ** 12) * 100
    }
  })
}

export const useHonoPoolInfo = () => {
  return useQuery({
    queryKey: ['HONO_pool_info'],
    queryFn: async () => {
      const price = await getPrice()
      const honoPoolInfo = price.honoPoolInfo

      return honoPoolInfo
    }
  })
}

export const useInitHONOPrice = () => {
  const { data: ethBalance } = useETHBalanceInHONOV2()
  const { data: HONOTotalSupply } = useTotalSupply(HONO_V2_ADDRESS)

  return useQuery({
    queryKey: ['HONO_init_price', ethBalance, HONOTotalSupply],
    queryFn: async () => {
      return ethBalance && HONOTotalSupply && +ethBalance / +HONOTotalSupply
    },
    enabled: !!ethBalance && !!HONOTotalSupply,
    refetchInterval: REFRESH_INTERVAL * 5
  })
}
