import { useMemo } from 'react'
import { formatEther as originFormatEther } from 'viem'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import { Box, Card, Typography } from '@mui/material'
import styled from '@emotion/styled'

import Span from '@jumbo/shared/Span'
import { PairItem } from 'app/types/LP'
import { useFarmInfo } from 'app/hooks/farm/useFarm'
import { HarvestAction } from './HarvestAction'
import { formatDateTime, formatNumber } from 'app/utils/helper'

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1)
}))

export interface FarmItemProps {
  farm: PairItem
}

export const FarmLogo = ({ src, alt }: { src: string; alt?: string }) => {
  return (
    <Avatar
      sx={{
        width: 32,
        height: 32
      }}
      alt={`${alt}`}
      src={src}
    />
  )
}

const formatEther = (num: unknown) => {
  return originFormatEther(num as unknown as bigint)
}

export const FarmItem = ({ farm }: FarmItemProps) => {
  const { data: farmInfo, isLoading } = useFarmInfo({
    tokenId: farm.tokenId,
    lpAddress: farm.lpAddress
  })

  const deltaRewards = useMemo(() => {
    if (farmInfo?.stakeInfo && farmInfo?.userInfo) {
      return +formatEther(
        // @ts-ignore
        (farmInfo.stakeInfo.accETHPerShare * farmInfo.userInfo.amount) / 10n ** 12n - farmInfo.userInfo.rewardDebt
      )
    }
  }, [farmInfo])

  const pendingEarning = useMemo(() => {
    if (deltaRewards) {
      return deltaRewards
    }

    return 0
  }, [deltaRewards])

  const totalEarning = useMemo(() => {
    if (farmInfo?.userInfo.totalReward) {
      return +formatEther(farmInfo?.userInfo.totalReward)
    }

    return 0
  }, [farmInfo])

  const lastRewardTime = useMemo(() => {
    if (farmInfo?.stakeInfo) {
      return formatDateTime(new Date(+farmInfo.stakeInfo.lastRewardTime.toString() * 1000))
    }

    return 0
  }, [farmInfo])

  return (
    <Card sx={{ mb: 1 }}>
      <Box
        display='flex'
        sx={{
          flexDirection: {
            xs: 'column',
            lg: 'row'
          },
          columnGap: {
            xs: 0,
            md: 2,
            lg: 3
          },
          alignItems: {
            xs: 'flex-start',
            lg: 'center'
          },
          p: theme => theme.spacing(2, 1)
        }}
      >
        <Item>
          <Stack direction={'row'} alignItems={'center'}>
            <Item>
              <Box display='flex'>
                <FarmLogo src={farm.logo1} alt={farm.name} />
                <FarmLogo src={farm.logo2} alt={farm.name} />
              </Box>
            </Item>
            <Item>
              <Typography variant={'h6'} mb={0.5}>
                {farm.name}
              </Typography>
            </Item>
          </Stack>
        </Item>
        <Item
          sx={{
            alignSelf: 'flex-start'
          }}
        >
          <Typography variant={'h6'} mt={1} lineHeight={1.25}>
            Shares
          </Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {isLoading
              ? 'loading...'
              : farmInfo?.userInfo.amount
              ? formatNumber(+farmInfo?.userInfo.amount.toString())
              : '0'}
          </Typography>
        </Item>
        <Item
          sx={{
            alignSelf: 'flex-start'
          }}
        >
          <Typography variant={'h6'} mt={1} lineHeight={1.25}>
            Total Earnings
          </Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {isLoading ? 'loading...' : formatNumber(+totalEarning)}&nbsp;ETH
          </Typography>
        </Item>
        <Item
          sx={{
            alignSelf: 'flex-start'
          }}
        >
          <Typography variant={'h6'} mt={1} lineHeight={1.25}>
            Pending Earnings
          </Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {isLoading ? 'loading...' : formatNumber(+pendingEarning)}&nbsp;ETH
          </Typography>
        </Item>
        <Item
          sx={{
            alignSelf: 'flex-start'
          }}
        >
          <Typography variant={'h6'} mt={1} lineHeight={1.25}>
            Last Reward Update
          </Typography>
          <Typography variant={'body1'} color='text.secondary'>
            {isLoading ? 'loading...' : lastRewardTime}
          </Typography>
        </Item>
        <Item
          sx={{
            mt: {
              xs: 1,
              md: 0
            }
          }}
        >
          <HarvestAction tokenId={farm.tokenId} lpAddress={farm.lpAddress} />
        </Item>
      </Box>
    </Card>
  )
}
