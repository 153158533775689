import { useMutation } from '@tanstack/react-query'
import { parseEther } from 'viem'
import { waitForTransaction } from 'wagmi/actions'
import toast from 'react-hot-toast'

import { useDynamicContract } from '../useContract'
import honoV2 from 'app/utils/constants/abis/honoV2.json'
import { HONO_V2_ADDRESS } from 'app/utils/constants/contract'

export const useConvertHONOToHONOV2 = () => {
  const honoV2Contract = useDynamicContract(HONO_V2_ADDRESS, honoV2 as any)

  return useMutation({
    mutationFn: async ({ amount }: { amount: string }) => {
      if (!honoV2Contract) {
        throw new Error('HONO v2 contract is not available')
      }

      const hash = await honoV2Contract?.write.convertOldHonoToNewHONO([parseEther(amount)])
      const receipt = await waitForTransaction({
        hash
      })

      return receipt
    },
    onSuccess: (_, { amount }) => {
      toast.success(`Converted successfully ${amount} HONO`)
    }
  })
}
