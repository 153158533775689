import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 10,
  backgroundColor: theme.palette.grey[200],
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10
  }
}))

export interface ProgressBarProps {
  value: number
}

export const ProgressBar = ({ value }: ProgressBarProps) => {
  return <BorderLinearProgress color='error' variant='determinate' value={value} />
}
