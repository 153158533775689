import { Box, Card, CardContent, Typography } from '@mui/material'

import { formatCurrency, formatNumber } from 'app/utils/helper'
import { useETHPrice } from 'app/hooks/graph/useGraph'
import { useTotalSupply } from 'app/hooks/useBalance'
import { HONO_ADDRESS, HONO_V2_ADDRESS } from 'app/utils/constants/contract'

export interface TotalBackingProps {
  ethBalance: number
  ethBalanceV2: number
}

export const TotalBacking = ({ ethBalance, ethBalanceV2 }: TotalBackingProps) => {
  const { data: ethPrice } = useETHPrice()
  const { data: HONOTotalSupply } = useTotalSupply(HONO_ADDRESS)
  const { data: HONOV2TotalSupply } = useTotalSupply(HONO_V2_ADDRESS)

  return (
    <Card>
      <CardContent
        sx={{ pt: '12px', pb: '8px', textAlign: 'center', color: 'common.white', bgcolor: 'secondary.main' }}
      >
        <Typography variant={'h1'} fontSize={'20px'} fontWeight={500} color={'inherit'}>
          TOTAL BACKING
        </Typography>
      </CardContent>

      <Box
        display='flex'
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
          columnGap: {
            xs: 0,
            md: 2
          }
        }}
      >
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant={'h1'} fontSize={'18px'} fontWeight={500} color={'inherit'}>
            HONO V1
          </Typography>
          <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
            {ethBalance.toFixed(6) ?? 0} ETH
          </Typography>
          <Typography variant={'h3'} fontSize={'16px'}>
            ~{`${ethBalance && ethPrice && formatCurrency((+ethBalance || 0) * (+ethPrice || 0))}`}
          </Typography>
          <Typography variant={'h2'} fontSize={'14px'}>
            Total supply of HONO V1: ~{formatNumber(+(HONOTotalSupply ?? 0))}
          </Typography>
        </CardContent>

        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant={'h1'} fontSize={'18px'} fontWeight={500} color={'inherit'}>
            HONO V2
          </Typography>
          <Typography variant={'h2'} fontSize={'20px'} fontWeight={500}>
            {ethBalanceV2.toFixed(6) ?? 0} ETH
          </Typography>
          <Typography variant={'h3'} fontSize={'16px'}>
            ~{`${ethBalanceV2 && ethPrice && formatCurrency((+ethBalanceV2 || 0) * (+ethPrice || 0))}`}
          </Typography>
          <Typography variant={'h2'} fontSize={'14px'}>
            Total supply of HONO V2: ~{formatNumber(+(HONOV2TotalSupply ?? 0))}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}
