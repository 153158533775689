import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

import routes from './routes'
import useJumboRoutes from '@jumbo/hooks/useJumboRoutes'

const AppRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, title: document.title })
  }, [location])

  const appRoutes = useJumboRoutes(routes)

  return <React.Fragment>{appRoutes}</React.Fragment>
}

export default AppRoutes
