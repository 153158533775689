import React, { useMemo } from 'react'
import { toast } from 'react-hot-toast'
import { useAccount } from 'wagmi'
import { Box, Typography } from '@mui/material'
import { ContentCopy } from '@mui/icons-material'
import { sc } from 'app/utils/constants/contract'

export const ReferrerLink = () => {
  const { address: originAddress = '' } = useAccount()
  const address = useMemo(() => {
    return sc.encryptToQRCodeAlphanumeric(originAddress)
  }, [originAddress])

  const handleCopy = () => {
    navigator.clipboard.writeText(`${window.location.origin}?referrer=${address}`)
    toast.success('Copied to clipboard')
  }

  return (
    <Box mb='2rem'>
      <Box>Your referral link:</Box>
      <Box display='flex' columnGap='1rem' alignItems='center'>
        <Typography
          variant={'h6'}
          mb={0}
          sx={{
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            color: theme => theme.palette.secondary.main
          }}
        >
          {`${window.location.origin}?referrer=${address}`}
        </Typography>
        <Box sx={{ cursor: 'pointer' }} onClick={handleCopy}>
          <ContentCopy />
        </Box>
      </Box>
    </Box>
  )
}
