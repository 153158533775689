import { useState } from 'react'
import { Address } from 'viem'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import toast from 'react-hot-toast'

import { RemoveLPParams, useRemoveLP } from 'app/hooks/liquidity/useLiquidityPool'

export interface RemoveLPProps extends Omit<RemoveLPParams, 'liquidity'> {
  lpAddress: Address
  poolBalance: string
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 360,
  margin: '0 auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 6,
  textAlign: 'center' as 'center'
}

export const RemoveLP = ({ tokenId, slippage, lpAddress, poolBalance }: RemoveLPProps) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(25)

  const toggleOpen = () => {
    setOpen(state => !state)
  }

  const { mutateAsync: removeLP } = useRemoveLP(lpAddress, tokenId)

  const handleRemoveLP = async () => {
    try {
      setLoading(true)
      await removeLP({
        tokenId,
        liquidity: parseFloat(+poolBalance * (value / 100) + '').toFixed(0) + '',
        slippage
      })
      toggleOpen()
    } catch (err) {
      toast.error(JSON.stringify(err))
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingButton loading={loading} variant={'outlined'} color='secondary' size={'small'} onClick={toggleOpen}>
        Remove LP
      </LoadingButton>

      <Modal open={open} onClose={toggleOpen} aria-labelledby='remove-liquidity-modal-title'>
        <Box display='flex' flexDirection='column' alignItems='center' sx={style}>
          <Typography id='remove-liquidity-modal-title' variant='h4' component='h2'>
            Remove Liquidity
          </Typography>
          <Box
            sx={{
              width: '160px'
            }}
          >
            <Slider
              aria-label='remove liquidity'
              valueLabelDisplay='auto'
              defaultValue={25}
              step={25}
              value={value}
              marks
              min={0}
              max={100}
              onChange={(e: any) => {
                setValue(+e.target.value)
              }}
            />
          </Box>
          <p>
            <span style={{ fontWeight: '600' }}>{parseFloat(+poolBalance * (value / 100) + '').toFixed(0) + ''}</span>{' '}
            LP to be removed.
          </p>
          <LoadingButton
            loading={loading}
            variant={'outlined'}
            size={'small'}
            disabled={value === 0}
            onClick={handleRemoveLP}
          >
            Remove LP
          </LoadingButton>
        </Box>
      </Modal>
    </>
  )
}
