import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import JumboCardQuick from '@jumbo/components/JumboCardQuick/JumboCardQuick'
import TextField from '@mui/material/TextField'
import { USDC_ADDRESS, CURBOND_ADDRESS } from 'app/utils/constants/contract'
import { MaxAmount } from 'app/components/MaxAmount'
import { formatNumber } from 'app/utils/helper'
import { useBalance } from 'app/hooks/useBalance'
import { MintXAction } from './MintXAction'

// @todo: update this
export const MintX = () => {
  const { data: balanceUSDC } = useBalance(USDC_ADDRESS)
  const { data: balanceX } = useBalance(CURBOND_ADDRESS)
  const [amount, setAmount] = useState('')

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
  }

  const handleChangeMax = (maxAmount: string) => {
    if (balanceUSDC && maxAmount) {
      const value = +maxAmount < +balanceUSDC ? maxAmount : balanceUSDC
      setAmount(value)
    }
  }

  return (
    <div>
      <Typography variant='h5' mb={3}>
        <div
          style={{
            lineHeight: '1.5rem',
            marginBottom: '1rem',
            color: '#475259'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              minWidth: 0,
              flex: 1,
              flexDirection: 'column'
            }}
          >
            <iframe
              title="Curation Entertainment's Pitch Deck"
              src='https://docs.google.com/presentation/d/e/2PACX-1vSoFVcy7mDWHhry241XvB9zESaTUZiwSSJIUU47Ds6G6u2lUD0luZQMjEeHI-CQomNcRJlW3Bf1nLxE/embed?start=false&loop=false&delayms=15000'
              allowFullScreen={true}
              height={500}
            />
          </Box>
          <p>
            Curation Entertainment is a startup organization by Creators for Creators. The entertainment marketplace is
            always expanding, changing and with that, so are stories and how they are disseminated.
          </p>
          <p>
            The streaming & digital market has room for additional players to carve out a space where Creators can
            experiment, help each other and forge a new economic revolution. We are not interested in rebooting IP. We
            look to invest in Creators with fresh, new perspectives and make what they love doing into a real career,
            leading the new Hollywood 3.0.
          </p>
          <p>Curation Entertainment is raising funds to build BiJuKu, a digital publishing platform to:</p>
          <ul>
            <li
              style={{
                marginTop: -10
              }}
            >
              Allow AI tooling to improve content visibility, user personalization and effective rankings among
              readers/users
            </li>
            <li>
              Tutor Creators on improving their storytelling skills, refining their craft, build up a talent base that
              the Community supports
            </li>
            <li>This establishes a Masterclass-type experience that would only be available via a subscription</li>
          </ul>
        </div>
      </Typography>

      <Box>
        {/* @ts-ignore */}
        <JumboCardQuick headerSx={{ pb: 0 }} sx={{ pb: 0 }}>
          <Typography
            variant={'h4'}
            sx={{
              mb: '0.5rem'
            }}
            textAlign='center'
          >
            Max raise: 8,000 USDC
          </Typography>
          <Typography
            variant={'h4'}
            sx={{
              mb: '0.5rem'
            }}
            textAlign='center'
          >
            Maturity date: 26 July 2025
          </Typography>

          <Typography
            variant={'h4'}
            sx={{
              mb: '1rem'
            }}
            textAlign='center'
          >
            Token to be bought back: $1.08 per token
          </Typography>

          <Typography variant={'h2'} textAlign='center'>
            {balanceX ? formatNumber(+balanceX) : '...'} CURBOND
          </Typography>
          <Typography variant={'h6'} mb={0} textAlign='center'>
            in your balance
          </Typography>
          <Box mt='1.5rem' textAlign='center'>
            <Box
              mx='auto'
              mb='0.5rem'
              sx={{
                width: {
                  xs: '100%',
                  sm: '360px'
                }
              }}
            >
              <TextField
                variant='outlined'
                type='number'
                sx={{
                  width: {
                    xs: '100%',
                    sm: '360px'
                  }
                }}
                onChange={handleChangeAmount}
                value={amount}
                InputLabelProps={{ shrink: true }}
              />
              <MaxAmount tokenAddress={USDC_ADDRESS} onChangeAmount={handleChangeMax} />
            </Box>
            <MintXAction amount={amount} />
          </Box>
        </JumboCardQuick>
      </Box>
    </div>
  )
}
