import { erc20ABI, useAccount } from 'wagmi'
import { waitForTransaction } from 'wagmi/actions'
import { Address } from 'viem'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { contractAllowanceQueryKey } from './useContractAllowance'
import { useDynamicContract } from './useContract'
import { MaxUint256 } from 'app/utils/constants/contract'

export const useApproveToken = (tokenAddress: Address) => {
  const client = useQueryClient()

  const { address = '' } = useAccount()
  const tokenContract = useDynamicContract<typeof erc20ABI>(tokenAddress, erc20ABI)

  return useMutation(
    async ({ spenderAddress }: { spenderAddress: Address }) => {
      if (!tokenAddress) throw new Error("Token doesn't exist on current network. Please switch networks.")
      if (!tokenContract) throw new Error("Contract doesn't exist on current network. Please switch networks.")

      const hash = await tokenContract.write.approve([spenderAddress, MaxUint256])
      const receipt = await waitForTransaction({
        hash
      })

      return receipt
    },
    {
      onError: error => console.log('ahihi error', error),
      onSuccess: async (data, variables) => {
        console.log('Successfully approved', data)
        setTimeout(async () => {
          await client.invalidateQueries({
            queryKey: contractAllowanceQueryKey(address, tokenAddress, variables.spenderAddress)
          })
        }, 1000)
      }
    }
  )
}
