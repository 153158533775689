import { ChangeEvent, useCallback, useState } from 'react'
import { Box, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useLocation } from 'react-router-dom'
import { Address } from 'viem'

import { PairItem } from 'app/types/LP'
import { LPAllowanceGuard } from 'app/components/AllowanceGuard'
import { AddLP } from './AddLP'
import { RemoveLP } from './RemoveLP'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'
import { MaxAmount } from 'app/components/MaxAmount'
import { isInternalLP } from 'app/utils/helper'
import { calcAmount1FromAmount0Ratio } from './utils'
import { getStorage } from 'app/utils/helper'
import { useDecryptAddress } from 'app/hooks/useBalance'

export interface LiquidityItemDetailProps {
  item: PairItem
  ratio: string
  poolBalance: string
  tickLower: number
  tickUpper: number
}

export const LiquidityItemDetail = ({ item, ratio, poolBalance, tickLower, tickUpper }: LiquidityItemDetailProps) => {
  const location = useLocation()

  const defaultReferrer = new URLSearchParams(location.search).get('referrer') as Address
  const storageReferrer = getStorage('@mangafi_referrer')

  const decryptedReferrer = useDecryptAddress(defaultReferrer || storageReferrer || '')

  const [name1, name2] = item.name.split('-')
  const [amount1, setAmount1] = useState('')
  const [amount2, setAmount2] = useState('')
  const [slippage, setSlippage] = useState('2')
  const [referrer, setReferrer] = useState<Address>(decryptedReferrer)

  const isToken1StableCoin = name1?.includes('USD')
  const isToken2StableCoin = name2?.includes('USD')

  const handleChangeAmount1 = useCallback(
    (value: string) => {
      setAmount1(value)
      const rate = calcAmount1FromAmount0Ratio(1, +ratio, {
        tickLower,
        tickUpper
      })
      const amount2 = rate * +value

      setAmount2(isToken2StableCoin ? parseFloat(amount2.toString()).toFixed(6) : amount2.toString())
    },
    [ratio, isToken2StableCoin, tickLower, tickUpper]
  )

  const handleChangeAmount2 = useCallback(
    (value: string) => {
      setAmount2(value)
      const rate =
        1 /
        calcAmount1FromAmount0Ratio(1, +ratio, {
          tickLower,
          tickUpper
        })
      const amount1 = rate * +value

      setAmount1(isToken1StableCoin ? parseFloat(amount1.toString()).toFixed(6) : amount1.toString())
    },
    [ratio, isToken1StableCoin, tickLower, tickUpper]
  )

  const onAmount1Change = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeAmount1(e.target.value)
  }

  const onAmount2Change = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeAmount2(e.target.value)
  }

  return (
    <>
      <Box display='flex' columnGap='0.25rem'>
        <TextField
          label='Slippage'
          variant='standard'
          sx={{
            mb: '1rem',
            width: '60px'
          }}
          type='number'
          onChange={e => setSlippage(e.target.value)}
          defaultValue={1}
          InputLabelProps={{ shrink: true }}
        />
        <Box marginTop='1.25rem'>%</Box>
      </Box>

      {!isInternalLP(item.tokenId) && (
        <TextField
          label='Referrer Address (optional)'
          variant='standard'
          sx={{
            mb: '1.5rem',
            width: {
              sm: '320px'
            }
          }}
          InputLabelProps={{ shrink: true }}
          onChange={e => setReferrer(e.target.value as Address)}
          value={referrer}
        />
      )}

      <Box
        display='flex'
        sx={{
          alignItems: {
            xl: 'center'
          },
          columnGap: {
            xl: '1rem'
          },
          flexDirection: {
            xs: 'column',
            xl: 'row'
          },
          rowGap: {
            sm: '1rem',
            lg: '0'
          }
        }}
      >
        <Box>
          <TextField
            label={name1}
            variant='outlined'
            sx={{
              width: {
                xs: '100%',
                sm: '360px'
              }
            }}
            type='number'
            value={amount1}
            onChange={onAmount1Change}
            InputLabelProps={{ shrink: true }}
          />
          <MaxAmount tokenAddress={item.address1} onChangeAmount={handleChangeAmount1} />
        </Box>

        <Box
          sx={{
            mx: {
              xs: 'auto',
              md: '0'
            },
            mt: {
              xl: '-1rem'
            }
          }}
        >
          <AddIcon />
        </Box>

        <Box>
          <TextField
            label={name2}
            variant='outlined'
            sx={{
              width: {
                xs: '100%',
                sm: '360px'
              }
            }}
            type='number'
            value={amount2}
            onChange={onAmount2Change}
            InputLabelProps={{ shrink: true }}
          />
          <MaxAmount tokenAddress={item.address2} onChangeAmount={handleChangeAmount2} />
        </Box>
      </Box>

      <Box display='flex' columnGap='1rem' textAlign='center' mt='1rem'>
        <ConnectWalletGuard>
          <NetworkGuard>
            <LPAllowanceGuard
              tokenAddress={[item.address1, item.address2]}
              tokenName={[name1, name2]}
              spenderAddress={item.lpAddress}
              amount1={amount1}
              amount2={amount2}
            >
              <AddLP
                tokenId={item.tokenId}
                amountAdd1={amount1}
                amountAdd2={amount2}
                slippage={slippage}
                lpAddress={item.lpAddress}
                referrer={referrer}
              />
            </LPAllowanceGuard>
            <RemoveLP
              tokenId={item.tokenId}
              lpAddress={item.lpAddress}
              slippage={slippage}
              poolBalance={poolBalance || ''}
            />
          </NetworkGuard>
        </ConnectWalletGuard>
      </Box>
    </>
  )
}
