import { useEffect, useState } from 'react'
import { Address, formatEther } from 'viem'
import { useAccount } from 'wagmi'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import TextField from '@mui/material/TextField'

import { MintAction } from './MintAction'
import { useBridgeFee } from 'app/hooks/bridge/useBridge'
import { MaxAmount } from 'app/components/MaxAmount'
import { INUKO_ADDRESS } from 'app/utils/constants/contract'
export interface MintAreaProps {
  rate: bigint
  bridgeAddress: Address
  tokenName: string
  tokenAddress: Address
}

const GET_FEE_INTERVAL = 5000

export const MintArea = ({ rate, bridgeAddress, tokenName, tokenAddress }: MintAreaProps) => {
  const { address = '' } = useAccount()
  const { mutateAsync: calculateFee } = useBridgeFee(bridgeAddress)

  const [inputAmount, setInputAmount] = useState('')
  const [outputAmount, setOutputAmount] = useState('')
  const [fee, setFee] = useState('')

  useEffect(() => {
    const setCalculateFee = async (address: Address) => {
      try {
        const fee = await calculateFee({
          amount: '10',
          senderAddress: address
        })
        const nativeFee = +formatEther(fee[0] as unknown as bigint)
        const zroFee = +formatEther(fee[1] as unknown as bigint)
        // add a little bit to avoid rounding error
        // contract will return remaining fee after transaction is completed
        setFee((nativeFee + zroFee) * 1.1 + '')
      } catch {}
    }

    if (address) {
      setCalculateFee(address)
      const intervalId = setInterval(() => {
        setCalculateFee(address)
      }, GET_FEE_INTERVAL)

      // Clean up the interval when the component unmounts or when the dependency array changes
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [address, calculateFee])

  const handleChangeAmount = async (amount: string) => {
    let outputRate = rate ? +formatEther(rate as unknown as bigint) : 1

    setInputAmount(amount)
    const outputAmount = (+amount * outputRate).toString()
    setOutputAmount(outputAmount)
  }

  const handleChangeInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = e.target.value
    handleChangeAmount(amount)
  }

  return (
    <Box my='2rem' textAlign='center'>
      <Typography variant='h5' color='text.primary'>
        Mint xHONO from {tokenName} on BSC
      </Typography>
      {tokenAddress === INUKO_ADDRESS && (
        <Typography
          variant='h6'
          color='text.primary'
          sx={{
            fontSize: '0.75rem'
          }}
        >
          Max amount per transaction: 25,000 INUKO
        </Typography>
      )}

      <Box display='flex' flexDirection='column' alignItems='center' rowGap='0.5rem' mt='2rem' mb='-3rem'>
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '360px'
            }
          }}
        >
          {/* <Box textAlign='right'>Fee: {isLoading ? `loading...` : fee ? parseFloat(fee).toFixed(6) : 0} BNB</Box> */}
          <TextField
            label={tokenName}
            sx={{
              width: '100%'
            }}
            type='number'
            onChange={handleChangeInput}
            value={inputAmount}
          />
          <MaxAmount tokenAddress={tokenAddress} onChangeAmount={handleChangeAmount} />
        </Box>

        <SwapVertIcon />

        <TextField
          label='xHONO'
          sx={{
            width: {
              xs: '100%',
              sm: '360px'
            }
          }}
          type='number'
          InputProps={{
            readOnly: true
          }}
          value={outputAmount}
        />

        <MintAction
          fee={fee}
          amount={inputAmount}
          bridgeAddress={bridgeAddress}
          tokenAddress={tokenAddress}
          tokenName={tokenName}
        />
      </Box>
    </Box>
  )
}
