import { useLocation } from 'react-router-dom'

import { routesForAuthenticatedOnly, routesForNotAuthenticatedOnly } from 'app/routes'

const useRoutePathMatch = (isAuth = false) => {
  const location = useLocation()
  const routes = isAuth ? routesForAuthenticatedOnly : routesForNotAuthenticatedOnly

  const path = location?.pathname

  const paths = routes.map(route => route.path)
  if (paths.length === 1) {
    return paths[0] === path
  }

  return paths.includes(path)
}

export default useRoutePathMatch
