import Stack from '@mui/material/Stack'
import { Box, Card, Typography } from '@mui/material'
import styled from '@emotion/styled'

import Span from '@jumbo/shared/Span'
import { PairItem } from 'app/types/LP'
import { ReferrerReward } from 'app/pages/harvest/ReferrerReward'
import { FarmLogo } from '../harvest/FarmItem'

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1)
}))

export interface ReferralItemProps {
  farm: PairItem
}

export const ReferralItem = ({ farm }: ReferralItemProps) => {
  return (
    <Card sx={{ mb: 1 }}>
      <Box
        width='80%'
        display='flex'
        sx={{
          columnGap: {
            xs: 1,
            md: 3,
            lg: 6
          },
          flexDirection: {
            xs: 'column',
            lg: 'row'
          },
          alignItems: {
            xs: 'flex-start',
            lg: 'center'
          },
          p: theme => theme.spacing(2, 1)
        }}
      >
        <Item>
          <Stack direction={'row'} alignItems={'center'}>
            <Item>
              <Box display='flex'>
                <FarmLogo src={farm.logo1} alt={farm.name} />
                <FarmLogo src={farm.logo2} alt={farm.name} />
              </Box>
            </Item>
            <Item>
              <Typography variant={'h6'} mb={0.5}>
                {farm.name}
              </Typography>
            </Item>
          </Stack>
        </Item>
        <ReferrerReward tokenId={farm.tokenId} lpAddress={farm.lpAddress} />
      </Box>
    </Card>
  )
}
