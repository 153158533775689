import { Typography } from '@mui/material'

import { PageTitle } from 'app/components/PageTitle'
import { CrowdfundingList } from './CrowdfundingList'

export interface CrowdfundingProps {}

export const Crowdfunding = () => {
  return (
    <div>
      <PageTitle title='MangaFi DApp: Crowdfunding' />
      <Typography variant='h1' color='text.primary'>
        Crowdfunding
      </Typography>
      <Typography variant='body1' color='text.secondary' mb={3}>
        The fund raising platform for entertainment content.
      </Typography>

      <CrowdfundingList />
    </div>
  )
}
