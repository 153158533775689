import React from 'react'
import useJumboApp from '@jumbo/hooks/useJumboApp'
import { TermOfUseModal } from 'app/components/Modals/TermOfUseModal'
import { AnnouncementModal } from 'app/components/Modals/AnnouncementModal'
import { config } from '../../../app/config/main'

const Page = ({ component, layout, ...restProps }) => {
  const { activeLayout, setActiveLayout } = useJumboApp()

  React.useEffect(() => {
    if (layout !== activeLayout) {
      setActiveLayout(layout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout])

  const PageComponent = component

  return (
    <>
      <TermOfUseModal />
      <AnnouncementModal />
      <PageComponent {...restProps} />
    </>
  )
}

Page.defaultProps = {
  layout: config.defaultLayout
}

export default Page
