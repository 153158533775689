import { request, gql } from 'graphql-request'
import { useQuery } from '@tanstack/react-query'
import { useChainId, usePublicClient } from 'wagmi'
import { formatEther } from 'viem'

import {
  HONO_BACKING_SUBGRAPH_URL,
  HONO_ADDRESS,
  HONO_HELPER_ADDRESS,
  USDC_ADDRESS,
  LINK_ADDRESS,
  HONO_V2_ADDRESS,
  WST_ETH_ADDRESS,
  HONO_BACKING_SUBGRAPH_V2_URL
} from 'app/utils/constants/contract'
import { REFRESH_INTERVAL } from 'app/utils/constants/app'
import { useETHPrice, useHONOPrice, useLinkPrice } from './graph/useGraph'
import { useStaticContract } from './useContract'
import { useBalanceOf, useNativeBalanceOf } from './useBalance'

import honoHelperABI from 'app/utils/constants/abis/hono_helper.json'
import { getTVL } from './useGraphDataFromFile'

export interface BackPrice {
  id: string
  HonoPerFancoin: string
  totalFanCoinSupply: string
  TotalHonoBacking: string
  blockTimeStamp: string
}

export interface BackPrice2 {
  id: string
  WSTETHPerHONO: string
  ETHPerHONO: string
  totalFanCoinSupply: string
  TotalHonoBacking: string
  blockTimeStamp: string
}

const startBlockV2 = 1723083539

export const useBackPrice = () => {
  return useQuery({
    queryKey: ['HONO_back_price'],
    queryFn: async () => {
      const { backPriceUpdates } = await request<{
        backPriceUpdates: BackPrice[]
      }>(
        HONO_BACKING_SUBGRAPH_URL,
        gql`
          query {
            backPriceUpdates(
              where: { blockTimeStamp_lt: "${startBlockV2}" }
              first: 1000
              orderBy: blockTimeStamp
              orderDirection: desc
            ) {
              id
              HonoPerFancoin
              totalFanCoinSupply
              TotalHonoBacking
              blockTimeStamp
            }
          }
        `
      )

      return backPriceUpdates
    }
  })
}

export const useBackPrice2 = () => {
  return useQuery({
    queryKey: ['HONO_back_price2'],
    queryFn: async () => {
      const { backPriceUpdate2S } = await request<{
        backPriceUpdate2S: BackPrice2[]
      }>(
        HONO_BACKING_SUBGRAPH_V2_URL,
        gql`
          query {
            backPriceUpdate2S(
              where: { blockTimeStamp_gte: "${startBlockV2}" }
              first: 1000
              orderBy: blockTimeStamp
              orderDirection: desc
            ) {
              id
              WSTETHPerHONO
              ETHPerHONO
              totalFanCoinSupply
              TotalHonoBacking
              blockTimeStamp
            }
          }
        `
      )

      return backPriceUpdate2S
    }
  })
}

export const usePast24HBackPrice = (timestamp: number) => {
  const cur = timestamp * 1000
  const past24H = cur - 86400 * 1000
  const past24HFormated = past24H / 1000

  return useQuery({
    queryKey: ['HONO_back_price', past24HFormated],
    queryFn: async () => {
      if (timestamp) {
        const { backPriceUpdates } = await request<{
          backPriceUpdates: {
            id: string
            HonoPerFancoin: string
            totalFanCoinSupply: string
            TotalHonoBacking: string
            blockTimeStamp: string
          }[]
        }>(
          HONO_BACKING_SUBGRAPH_URL,
          gql`
            query {
              backPriceUpdates(where: { blockTimeStamp_lt: ${past24HFormated} }, limit: 1) {
                id
                HonoPerFancoin
                totalFanCoinSupply
                TotalHonoBacking
                blockTimeStamp
              }
            }
          `
        )

        return backPriceUpdates[0]
      }
    },
    enabled: !!timestamp
  })
}

export const useETHBalanceInHONO = () => {
  const chainId = useChainId()
  const provider = usePublicClient({
    chainId
  })
  return useQuery({
    queryKey: ['HONO_ETH_balance', chainId],
    queryFn: async () => {
      const ethBalance = await provider.getBalance({
        address: HONO_ADDRESS
      })

      return formatEther(ethBalance)
    }
  })
}

export const useETHBalanceInHONOV2 = () => {
  return useBalanceOf({
    tokenAddress: WST_ETH_ADDRESS,
    contractAddress: HONO_V2_ADDRESS
  })
}

export const useHONOHolders = () => {
  return useQuery({
    queryKey: ['HONO_holders'],
    queryFn: async () => {
      const { holderCounts } = await request<{
        holderCounts: {
          count: number
        }[]
      }>(
        `${HONO_BACKING_SUBGRAPH_URL}`,
        gql`
          query TokenHolders {
            holderCounts {
              count
            }
          }
        `
      )

      return holderCounts?.[0] ? +holderCounts[0].count : 0
    },
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useLatestRevenue = () => {
  return useQuery({
    queryKey: ['HONO_latest_revenue'],
    queryFn: async () => {
      const { dailyRevenueAggregators } = await request<{
        dailyRevenueAggregators: {
          id: string
          todayETHRevenue: string
        }[]
      }>(
        `${HONO_BACKING_SUBGRAPH_URL}`,
        gql`
          query LatestRevenue {
            dailyRevenueAggregators(orderBy: id, orderDirection: desc, first: 1) {
              id
              todayETHRevenue
            }
          }
        `
      )

      if (dailyRevenueAggregators?.length) {
        return {
          value: formatEther(dailyRevenueAggregators[0].todayETHRevenue as unknown as bigint),
          date: +dailyRevenueAggregators[0].id * 1000
        }
      }
    },
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useTotalRevenue = () => {
  return useQuery({
    queryKey: ['HONO_total_revenue'],
    queryFn: async () => {
      const { totalRevenueTrackers } = await request<{
        totalRevenueTrackers: {
          totalETHRevenue: string
        }[]
      }>(
        `${HONO_BACKING_SUBGRAPH_URL}`,
        gql`
          query TotalRevenue {
            totalRevenueTrackers(first: 1) {
              totalETHRevenue
            }
          }
        `
      )

      if (totalRevenueTrackers?.length) {
        return formatEther(totalRevenueTrackers[0].totalETHRevenue as unknown as bigint)
      }
    },
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useHONOUsdcTVL = () => {
  return useQuery({
    queryKey: ['hono_usdc_tvl'],
    queryFn: async () => {
      const tvl = await getTVL()
      return tvl.honoUsdc
    }
  })
}

export const useETHLinkTVL = () => {
  return useQuery({
    queryKey: ['eth_link_tvl'],
    queryFn: async () => {
      const tvl = await getTVL()
      return tvl.ethLink
    }
  })
}

export const useBackUpAssetManagement = () => {
  const backupAddress = '0x8106f085262B2dD861D2764590619036B16bb3C0'
  const { data: ethPrice } = useETHPrice()
  const { data: linkPrice } = useLinkPrice()
  const { data: honoPrice } = useHONOPrice()

  const { data: USDC } = useBalanceOf({
    tokenAddress: USDC_ADDRESS,
    contractAddress: backupAddress
  })
  const { data: LINK } = useBalanceOf({
    tokenAddress: LINK_ADDRESS,
    contractAddress: backupAddress
  })
  const { data: HONO } = useBalanceOf({
    tokenAddress: HONO_ADDRESS,
    contractAddress: backupAddress
  })
  const { data: ETH } = useNativeBalanceOf(backupAddress)

  return useQuery({
    queryKey: ['back_up_asset_management', ethPrice, linkPrice, honoPrice, USDC, LINK, HONO, ETH],
    queryFn: async () => {
      const linkValue = +(LINK ?? 0) * +(linkPrice ?? 0)
      const honoValue = +(HONO ?? 0) * +(honoPrice ?? 0)
      const ethValue = +(ETH ?? 0) * +(ethPrice ?? 0)

      return +(USDC ?? 0) + linkValue + honoValue + ethValue
    },
    refetchInterval: REFRESH_INTERVAL
  })
}

export const useXHONOConverted = () => {
  const contract = useStaticContract(HONO_HELPER_ADDRESS, honoHelperABI as any)

  return useQuery({
    queryKey: ['xhono_converted'],
    queryFn: async () => {
      if (contract) {
        const xhonoConverted = await contract.read.totalHonoToXHonoConverted([])

        return formatEther(xhonoConverted as unknown as bigint)
      }
      return 0
    },
    refetchInterval: REFRESH_INTERVAL
  })
}
