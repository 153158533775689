import { Address } from 'viem'
import LoadingButton from '@mui/lab/LoadingButton'

import { useClaimReferrerReward } from 'app/hooks/farm/useFarm'
import { ConnectWalletGuard } from 'app/components/ConnectWalletGuard'
import { NetworkGuard } from 'app/components/NetworkGuard'

export interface ClaimReferrerActionProps {
  amount: string
  tokenId: number
  lpAddress: Address
}

export const ClaimReferrerAction = ({ tokenId, lpAddress, amount }: ClaimReferrerActionProps) => {
  const { mutate: claim, isLoading } = useClaimReferrerReward({
    tokenId,
    lpAddress
  })
  return (
    <ConnectWalletGuard>
      <NetworkGuard>
        <LoadingButton
          loading={isLoading}
          sx={{ minWidth: 92 }}
          variant={'contained'}
          size={'small'}
          color='secondary'
          onClick={() => claim(amount)}
        >
          Claim
        </LoadingButton>
      </NetworkGuard>
    </ConnectWalletGuard>
  )
}
